import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import SideDrawer from "../../Components/SideDrawer";
import { Button, Col, Row, Switch, Typography, Modal, Tooltip } from "antd";
import { IllumaCredentialModal } from "../../Components/IllumaCredentialModal";
import { InfosumCredentialsModal } from "../../Components/InfosumCredentialsModal";
import { createIllumaConnection, deleteIllumaConnection, editIllumaConnection, getIllumaConnections, getIllumaStats } from "../../Utils/ApiActions";
import { UIDNoCredentialModal } from "../../Components/UIDNoCredentialModal";
import { DataConnectorCredentialModal } from "../../Components/CommonModalDataConnectors";

import illumaGreyLogo from '../../assets/illuma-grey.png'
import { formatNumberWithCommas } from "../../Utils/Helper";

export function IllumaConnections() {
    const [showNewConnectionModal, setShowNewConnectionModal] = useState(false);
    const [showCredentialsModal, setShowCredentialsModal] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [rssFeedEntries, setRssFeedEntries] = useState([]);
    const [illumaConnectectionId, setIllumaConnectectionId] = useState(undefined);
    const [displayConnectorModal, setDisplayConnectorModal] = useState(false)

    const [apiCallsCount, setApiCallsCount] = useState(0)
    const [cacheCountapiCallsCount, setCacheApiCallCount] = useState(0)
    const [pageViewCounts, setPageViewsCount] = useState(0)

    useEffect(() => {
        getIlumaConnections();
        illumaStats()
    }, []);

    const illumaStats = async () => {
        const stats = await getIllumaStats()
        setPageViewsCount(stats?.data?.pageViewCounts)
        setApiCallsCount(stats?.data?.apiCallsCount)
        setCacheApiCallCount(stats?.data?.cacheCount)
    }

    const getIlumaConnections = async () => {
        const response = await getIllumaConnections();
        if (response.data.length > 0) {
            setApiKey(response.data[0].configuration.apiKey);
            setRssFeedEntries(response.data[0].configuration.rss_feeder);
            setIllumaConnectectionId(response.data[0].id);

        }
        else {
            setDisplayConnectorModal(true)
        }
        console.log(response);
    }

    const handleCreateConnection = async () => {
        const response = await createIllumaConnection("", "");
        setIllumaConnectectionId(response.data.id);
    }

    const handleEditConnection = async () => {
        const response = await editIllumaConnection(illumaConnectectionId, rssFeedEntries, apiKey);
        if (response.success) {
            setShowCredentialsModal(false);
        }
        getIlumaConnections()
    }

    const handleDeleteConnection = async () => {

        const response = await deleteIllumaConnection(illumaConnectectionId);
        setIllumaConnectectionId(undefined);
    }

    function renderEmptySection() {
        return (
            <div style={{
                marginLeft: 90,
                marginRight: 90,
                minHeight: '50vh',
                background: 'black',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Typography.Text style={{
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '24px',
                    color: '#fff'
                }}>Connect lockr with Illuma</Typography.Text>
                <Button
                    style={{ marginTop: 24 }}
                    type='primary'
                    disabled={illumaConnectectionId}
                    className='btn-first-connection'
                    onClick={() => {
                        setShowNewConnectionModal(true);
                    }}>Create connection</Button>
            </div>
        );
    }

    const renderIllumaStatsStrips = () => {
        return <div
            style={{
                alignItems: "center",
                display: "flex",
                position: "relative",
                flexDirection: "row",
                gap: 10,
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 32,
                width: "100%",
                background: "#1D1D1D",
                height: "150px",
                boxShadow: "8px 8px 0px 0px #000",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    height: 127,
                    borderRadius: 2,
                    background: "black",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "relative",
                }}
            >
                <span
                    style={{
                        color: "#61CEF7",
                        fontSize: 40,
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                    }}
                >
                    {formatNumberWithCommas(apiCallsCount)}
                </span>
                <span
                    style={{
                        color: "#8B8B8B",
                        fontSize: 14,
                        fontWeight: "400",
                        marginTop: -4,
                        fontFamily: "Montserrat",
                    }}
                >
                    API Calls
                </span>
                <img
                    alt="add_logo"
                    style={{
                        height: 24,
                        zIndex: 200,
                        width: 24,
                        position: "absolute",
                        right: -16,
                    }}
                    src={require("../../assets/stats_add_logo.png")}
                />
                <Tooltip
                    title={"Displays the total number of unique API calls made to the Illuma service."}
                    overlayInnerStyle={{
                        padding: 16,
                    }}
                >
                    <InfoCircleOutlined
                        style={{
                            position: "absolute",
                            top: 15,
                            right: 15,
                            zIndex: 200,
                            color: "grey",
                            fontSize: 16,
                            fontWeight: "400",
                        }}
                    />
                </Tooltip>
            </div>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    height: 127,
                    borderRadius: 2,
                    position: "relative",
                    background: "black",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <span
                    style={{
                        color: "#61CEF7",
                        fontSize: 40,
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                    }}
                >
                    {cacheCountapiCallsCount}
                </span>
                <span
                    style={{
                        color: "#8B8B8B",
                        fontSize: 14,
                        fontWeight: "400",
                        marginTop: -4,
                        fontFamily: "Montserrat",
                    }}
                >
                    Cached API Calls
                </span>
                <img
                    alt="add_logo"
                    style={{
                        height: 24,
                        width: 24,
                        zIndex: 200,
                        position: "absolute",
                        right: -16,
                    }}
                    src={require("../../assets/stats_equal_logo.png")}
                />
                <Tooltip
                    title={"Redundant cache requests are avoided, ensuring faster and more efficient API calls on each page."}
                    overlayInnerStyle={{
                        padding: 16,
                    }}
                >
                    <InfoCircleOutlined
                        style={{
                            position: "absolute",
                            top: 15,
                            right: 15,
                            zIndex: 200,
                            color: "grey",
                            fontSize: 16,
                            fontWeight: "400",
                        }}
                    />
                </Tooltip>
            </div>
            <div
                onMouseEnter={() => {

                }}
                onMouseLeave={() => {

                }}
                style={{
                    display: "flex",
                    flex: 1,
                    height: 127,
                    borderRadius: 2,
                    background: "#26282A",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    position: "relative",
                }}
            >
                <span
                    style={{
                        color: "#50E8B1",
                        fontSize: 40,
                        fontWeight: "700",
                        fontFamily: "Montserrat",
                    }}
                >
                    {formatNumberWithCommas(pageViewCounts)}
                </span>
                <span
                    style={{
                        color: "#8B8B8B",
                        fontSize: 14,
                        fontWeight: "400",
                        marginTop: -4,
                        fontFamily: "Montserrat",
                    }}
                >
                    Total
                </span>

            </div>
        </div>
    }


    return (
        <SideDrawer>
            <Row
                justify='space-between'
                align='middle'
                className='apiandservices-newapp-btn'
            >
                <Col sm={17} xs={24}>
                    <Typography.Title className="apiandservices-heading">Illuma</Typography.Title>
                    <div style={{ marginTop: 8 }} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <label style={{ fontSize: 14, fontFamily: "Montserrat", color: '#8B8B8B' }} className='dashboard-as-of-today'>
                            Enable standard IAB contextual classifications
                        </label>
                        <div style={{ marginLeft: 20 }}>
                            <Switch
                                checked={!!illumaConnectectionId}
                                onChange={(checked) => {

                                    if (checked) {
                                        setShowCredentialsModal(true)
                                        handleCreateConnection();
                                    } else {
                                        handleDeleteConnection();
                                    }
                                }}
                            />
                        </div>
                        {illumaConnectectionId && <Typography.Text onClick={() => {
                            setShowCredentialsModal(true);
                        }} className="infosum-view-credentials-text" >Settings</Typography.Text>}
                    </div>
                </Col>
            </Row>
            {!illumaConnectectionId && renderEmptySection()}
            {illumaConnectectionId && <Row justify='space-between'
                align='middle'
                className='apiandservices-newapp-btn' style={{}}>{renderIllumaStatsStrips()}</Row>}

            <IllumaCredentialModal visible={showCredentialsModal} apiKey={apiKey} rssFeedEntries={rssFeedEntries} setApiKey={setApiKey} setRssFeedEntries={setRssFeedEntries} onCancel={() => {
                setShowCredentialsModal(false);
            }} onOk={() => {
                handleEditConnection();
            }} />

            <DataConnectorCredentialModal display={displayConnectorModal} onOk={() => { setDisplayConnectorModal(false) }} integrationType="illuma" logo={illumaGreyLogo} />
        </SideDrawer>
    );
} 