/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { CircleMarker, MapContainer, Popup, TileLayer } from "react-leaflet";
import { getAIMCalls, getAIMMapData } from "../../../Utils/ApiActions";
import {
  formatNumberWithCommas,
  identitProvidersType,
} from "../../../Utils/Helper";
import { Dropdown, Input } from "antd";
import { AiOutlineDown } from "react-icons/ai";
import { LockrFromToDatePicker } from "../../Connections/components/IdentityProviderStatsAuthStrip";
import moment from "moment";
import {
  BarChart,
  Cell,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  Bar,
  Pie,
} from "recharts";
import { AppSelectionDropdown } from "../../Connections/components/AppSelectionDropdown";
import { useDurationForAIMAnalyticsStore } from "../../../Store/durationForAIMAnalyticsStore";

function MapEventsDropDown({
  setSelectedEvent,
  eventOptions,
  supportPageView = true,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedMenuOptions, setSelectedMenuOptions] = useState([]);
  const [selectedMenuOptionsData, setSelectedMenuOptionsData] = useState([]);

  useEffect(() => {
    setSelectedEvent(selectedMenuOptionsData);
  }, [selectedMenuOptions]);

  function toggleEvent(eventData) {
    const event = eventData.data;
    const existingIndex = selectedMenuOptions.findIndex(
      (item) => item === event
    );
    if (existingIndex >= 0) {
      const _menuOptions = [...selectedMenuOptions];
      const _menuOptionsData = [...selectedMenuOptionsData];
      _menuOptions.splice(existingIndex, 1);
      _menuOptionsData.splice(existingIndex, 1);
      setSelectedMenuOptions(_menuOptions);
      setSelectedMenuOptionsData(_menuOptionsData);
      return;
    }
    setSelectedMenuOptions([...selectedMenuOptions, event]);
    setSelectedMenuOptionsData([...selectedMenuOptionsData, eventData]);
  }

  const menu = (
      <div
          style={{
            maxHeight: 500,
            width: 220,
            paddingLeft: 16,
            background: "black",
            overflowY: "scroll",
            overflowX: "hidden",
            paddingTop: 10,
            paddingBottom: 10,
          }}
      >
        <div
            onClick={() => {
              setSelectedMenuOptions([]);
              setSelectedMenuOptionsData([]);
            }}
            className="verticle-dropdown-option"
            style={{
              fontFamily: "Montserrat",
              color: selectedMenuOptions.length === 0 ? "#61CEF7" : "white",
            }}
        >
          {" "}
          <div style={{display: "inline-block", width: 32, height: 12}}>
            {selectedMenuOptions.length === 0 && (
                <img alt="tick" src={require("../../../assets/tick.png")}></img>
            )}
          </div>
          All
        </div>
        <div style={{marginTop: 10, paddingLeft: 16}}>
        <span
            style={{
              color: "white",
              fontFamily: "Montserrat",
              fontSize: 14,
              opacity: 0.5,
            }}
        >
          Partner Calls
        </span>
        </div>
        <div
            onClick={() => toggleEvent({data: "AIM_UID2", type: "integration"})}
            className="verticle-dropdown-option"
            style={{
              fontFamily: "Montserrat",
              color: selectedMenuOptions.includes("AIM_UID2") ? "#61CEF7" : "white",
            }}
        >
          {" "}
          <div style={{display: "inline-block", width: 32, height: 12}}>
            {selectedMenuOptions.includes("AIM_UID2") && (
                <img alt="tick" src={require("../../../assets/tick.png")}></img>
            )}
          </div>
          UID2
        </div>
        <div
            onClick={() =>
                toggleEvent({data: "AIM_RAMP_ID", type: "integration"})
            }
            className="verticle-dropdown-option"
            style={{
              fontFamily: "Montserrat",
              color: selectedMenuOptions.includes("AIM_RAMP_ID")
                  ? "#61CEF7"
                  : "white",
            }}
        >
          {" "}
          <div style={{display: "inline-block", width: 32, height: 12}}>
            {selectedMenuOptions.includes("AIM_RAMP_ID") && (
                <img alt="tick" src={require("../../../assets/tick.png")}></img>
            )}
          </div>
          RAMP ID
        </div>
        <div
            onClick={() => toggleEvent({data: "AIM_ID5", type: "integration"})}
            className="verticle-dropdown-option"
            style={{
              fontFamily: "Montserrat",
              color: selectedMenuOptions.includes("AIM_ID5") ? "#61CEF7" : "white",
            }}
        >
          {" "}
          <div style={{display: "inline-block", width: 32, height: 12}}>
            {selectedMenuOptions.includes("AIM_ID5") && (
                <img alt="tick" src={require("../../../assets/tick.png")}></img>
            )}
          </div>
          ID5
        </div>
        <div
            onClick={() => toggleEvent({data: "AIM_EUID", type: "integration"})}
            className="verticle-dropdown-option"
            style={{
              fontFamily: "Montserrat",
              color: selectedMenuOptions.includes("AIM_EUID") ? "#61CEF7" : "white",
            }}
        >
          {" "}
          <div style={{display: "inline-block", width: 32, height: 12}}>
            {selectedMenuOptions.includes("AIM_EUID") && (
                <img alt="tick" src={require("../../../assets/tick.png")}></img>
            )}
          </div>
          EUID
        </div>
        <div
            onClick={() => toggleEvent({data: "AIM_YAHOO_CONNECT_ID", type: "integration"})}
            className="verticle-dropdown-option"
            style={{
              fontFamily: "Montserrat",
              color: selectedMenuOptions.includes("AIM_YAHOO_CONNECT_ID") ? "#61CEF7" : "white",
            }}
        >
          {" "}
          <div style={{display: "inline-block", width: 32, height: 12}}>
            {selectedMenuOptions.includes("AIM_YAHOO_CONNECT_ID") && (
                <img alt="tick" src={require("../../../assets/tick.png")}></img>
            )}
          </div>
          Yahoo Connect ID
        </div>
        <div style={{marginTop: 10, paddingLeft: 16}}>
        <span
            style={{
              color: "white",
              fontFamily: "Montserrat",
              fontSize: 14,
              opacity: 0.5,
            }}
        >
          Client Side Events
        </span>
        </div>
        <div
            onClick={() => toggleEvent({data: "AIM_ID5_JS", type: "piggyback"})}
            className="verticle-dropdown-option"
            style={{
              fontFamily: "Montserrat",
              color: selectedMenuOptions.includes("AIM_ID5_JS")
                  ? "#61CEF7"
                  : "white",
            }}
        >
          {" "}
          <div style={{display: "inline-block", width: 32, height: 12}}>
            {selectedMenuOptions.includes("AIM_ID5_JS") && (
                <img alt="tick" src={require("../../../assets/tick.png")}></img>
            )}
          </div>
          ID5 JS
        </div>
        <div
            onClick={() => toggleEvent({data: "PAGE_VIEWS", type: "piggyback"})}
            className="verticle-dropdown-option"
            style={{
              fontFamily: "Montserrat",
              color: selectedMenuOptions.includes("PAGE_VIEWS")
                  ? "#61CEF7"
                  : "white",
            }}
        >
          {" "}
          <div style={{display: "inline-block", width: 32, height: 12}}>
            {selectedMenuOptions.includes("PAGE_VIEWS") && (
                <img alt="tick" src={require("../../../assets/tick.png")}></img>
            )}
          </div>
          Page Views
        </div>
        {eventOptions.length && (
            <div style={{marginTop: 10, paddingLeft: 16}}>
          <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                fontSize: 14,
                opacity: 0.5,
              }}
          >
            Login Events
          </span>
            </div>
        )}
        <div>
          {eventOptions.map((item) => {
            return (
                <div
                    className="verticle-dropdown-option"
                    style={{
                      fontFamily: "Montserrat",
                      marginTop: 10,
                      color: selectedMenuOptions.includes(item) ? "#61CEF7" : "white",
                    }}
                >
              <span
                  onClick={() => {
                    toggleEvent({data: item, type: "event"});
                  }}
              >
                <div
                    style={{
                      display: "inline-block",
                      width: 32,
                      height: 12,
                    }}
                >
                  {selectedMenuOptions.includes(item) && (
                      <img
                          alt="tick"
                          src={require("../../../assets/tick.png")}
                      ></img>
                  )}
                </div>
                {item}
              </span>
                </div>
            );
          })}
        </div>
      </div>
  );

  return (
      <Dropdown
          visible={showMenu}
          overlay={menu}
          onOpenChange={(event) => {
            setShowMenu(event);
          }}
          onClick={() => setShowMenu(!showMenu)}
          className="publishers-dropdown"
      >
        <div style={{position: "relative", cursor: "pointer"}}>
          <Input
              value={
                selectedMenuOptions.length === 0
                    ? "All"
                    : selectedMenuOptions.length === 1
                        ? selectedMenuOptions[0]
                            .replace("_", " ")
                            .replace("_", " ")
                  .replace("AIM", "")
              : "Multiple"
          }
          readOnly
          width={120}
          className="publishers-dropdown-input-card-selector"
          style={{ cursor: "pointer" }}
        ></Input>
        <AiOutlineDown
          style={{
            color: "white",
            position: "absolute",
            top: 16,
            right: 18,
            fontSize: 10,
          }}
        />
      </div>
    </Dropdown>
  );
}

function groupBy(data, format, startDate, endDate) {
  const result = [];
  //Preparing the rough data
  const traversingStartDate = moment(startDate.valueOf());
  while (traversingStartDate.isBefore(endDate)) {
    const rIndex = result.findIndex(
      (item) => item.date === traversingStartDate.format(format)
    );
    if (rIndex < 0) {
      result.push({
        date: moment(traversingStartDate).format(format),
        dataCount: 0,
      });
    }
    traversingStartDate.add(1, "day");
  }
  data.forEach((dItem) => {
    const dItemIndex = result.findIndex((rItem) => {
      return rItem.date === moment(dItem.date).format(format);
    });
    if (dItemIndex >= 0) {
      //add the keys.
      const rItem = result[dItemIndex];
      Object.keys(rItem).map((kItem) => {
        if (kItem !== "date" && kItem !== "dateItem" && kItem !== "createdAt") {
          rItem[kItem] =
            parseInt(rItem?.[kItem] ?? "0") + parseInt(dItem?.[kItem] ?? "0");
        }
      });
    } else {
      result.push({
        ...dItem,
        date: moment(dItem.date).format(format),
        format,
      });
    }
  });
  if (format === "WW") {
    result.forEach((item) => {
      item.date = moment(item.date, "WW").startOf("week").format("DD, MMM");
    });
  }
  return result;
}

export function AIMAnalyticsMaps({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  appIDs,
  setAppIDs,
}) {
  const [positions, setPositions] = useState([]);
  const [publisherColors, setPublisherColors] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]); //Type: integration / event
  const [menuLoginOptions, setMenuLoginOptions] = useState([]);
  const [supportPageViews, setSupportPageViews] = useState(true);
  const [aimCallsData, setAIMCallsData] = useState({});

  const { duration } = useDurationForAIMAnalyticsStore();

  const uniqueUserCount = useMemo(() => {
    if (aimCallsData.uniqueUserData?.length) {
      let total = 0;
      aimCallsData.uniqueUserData.forEach((item) => {
        total += parseInt(item.totalCount);
      });
      return total;
    }
  }, [aimCallsData]);

  const userGraphData = useMemo(() => {
    const data = [...(aimCallsData?.userPerTimeFrameData ?? [])];
    if (data) {
      let result = [];
      const daysDifference = endDate.diff(startDate, "day");
      if (daysDifference <= 1) {
        result = groupBy(data, "DD, MMM YYYY", startDate, endDate);
      } else if (daysDifference > 1 && daysDifference <= 7) {
        result = groupBy(data, "DD, MMM YYYY", startDate, endDate);
      } else if (daysDifference > 7 && daysDifference <= 31) {
        result = groupBy(data, "WW", startDate, endDate);
      } else if (daysDifference > 31 && daysDifference <= 366) {
        result = groupBy(data, "MMM", startDate, endDate);
      } else if (daysDifference > 366) {
        result = groupBy(data, "YYYY", startDate, endDate);
      }
      return result;
    }
    return [];
  }, [startDate, endDate, aimCallsData]);

  const myPositionsData = useMemo(() => {
    const result = [];
    positions.forEach((pItem) => {
      const pIndex = result.findIndex(
        (rItem) =>
          rItem.publisherID === pItem.publisherID &&
          rItem.country === pItem.country
      );
      if (pIndex < 0) {
        result.push({
          ...pItem,
          events: [
            {
              eventCode: pItem.eventCode,
              aimEventName:
                pItem.aimEventName === "null"
                  ? pItem.integrationType ?? ""
                  : pItem.aimEventName,
              eventCount: pItem.eventCount,
            },
          ],
        });
      } else {
        const eventsArray = result?.[pIndex]?.["events"] || [];
        const eIndex = eventsArray.findIndex(
          (eItem) => eItem.aimEventName === pItem.aimEventName
        );
        if (eIndex < 0) {
          result[pIndex]["events"].push({
            eventCode: pItem.eventCode,
            event: pItem.event,
            aimEventName:
              pItem.aimEventName === "null"
                ? pItem.integrationType ?? ""
                : pItem.aimEventName,
            eventCount: pItem.eventCount,
          });
        } else {
          result[pIndex]["events"][eIndex].eventCount =
            parseInt(pItem.eventCount) +
            parseInt(result[pIndex]["events"][eIndex].eventCount);
        }
      }
    });

    console.log("🚀 ~ myPositionsData ~ result:", result);
    return result;
  }, [positions]);

  const maxPositionItem = useMemo(() => {
    let max = 0;
    positions.forEach((pItem) => {
      if (parseInt(pItem.count) > max) {
        max = pItem.count;
      }
    });
    return max;
  }, [positions]);

  const minPositionItem = useMemo(() => {
    let min = maxPositionItem;
    positions.forEach((pItem) => {
      if (parseInt(pItem.count) < min) {
        min = pItem.count;
      }
    });
    return min;
  }, [positions]);

  const mySelectedEvents = useMemo(() => {
    return selectedEvent.map((item) => item.data);
  }, [selectedEvent]);

  const intervalRef = useRef({
    timer: undefined,
  });

  useEffect(() => {
    startPolling();
    fetchAIMMapData();
    fetchAIMUserData();
  }, [startDate, endDate, selectedEvent, appIDs]);

  function startPolling() {
    if (intervalRef.current.timer) {
      clearInterval(intervalRef.current.timer);
    }
    intervalRef.current.timer = setInterval(() => {
      fetchAIMMapData();
      fetchAIMUserData();
    }, 90000);
  }

  function getEventNameFromCode(item) {
    switch (item) {
      case "AIM_UID2":
        return identitProvidersType.UID;
      case "AIM_ID5":
        return identitProvidersType.ID5;
      case "AIM_RAMP_ID":
        return identitProvidersType.RAMPID;
      case "AIM_EUID":
        return identitProvidersType.EUID;
      case "AIM_YAHOO_CONNECT_ID":
        return identitProvidersType.YAHOO_CONNECT_ID;
      default:
        return identitProvidersType.UID;
    }
  }

  function getEventCode(item) {
    if (item.eventCode === 3001) {
      return "AIM_ID5_JS";
    }
    if (item.integrationType === identitProvidersType.RAMPID) {
      return "AIM_RAMP_ID";
    }
    if (item.integrationType === identitProvidersType.UID) {
      return "AIM_UID2";
    }
    if (item.integrationType === identitProvidersType.ID5) {
      return "AIM_ID5";
    }
    if (item.integrationType === identitProvidersType.EUID) {
      return "AIM_EUID";
    }
  }

  async function fetchAIMMapData() {
    try {
      const integrationTypes = [];
      const aimEventNames = [];
      if (selectedEvent.length) {
        selectedEvent.forEach((item) => {
          if (item.type === "integration") {
            integrationTypes.push(getEventNameFromCode(item.data));
          } else if (item.type === "event") {
            aimEventNames.push(item.data);
          } else if (item.type === "piggyback") {
            aimEventNames.push(item.data);
          }
        });
      }
      //For all, we need to check for the selected event option.
      const supportPageViews =
        aimEventNames.includes("PAGE_VIEWS") || selectedEvent.length === 0;
      if (aimEventNames.includes("PAGE_VIEWS")) {
        const pageViewIndex = aimEventNames.indexOf("PAGE_VIEWS");
        aimEventNames.splice(pageViewIndex, 1);
        aimEventNames.push("PAGE_VIEW");
      }

      if (aimEventNames.indexOf("AIM_ID5_JS") >= 0) {
        aimEventNames.splice(aimEventNames.indexOf("AIM_ID5_JS"), 1);
        aimEventNames.push("ID5_PIGGY_BACKED");
      }

      const response = await getAIMMapData(
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        integrationTypes,
        aimEventNames,
        supportPageViews,
        appIDs
      );

      if (response.success) {
        response?.publisherEvents?.length > 0 &&
          setMenuLoginOptions(
            response.publisherEvents
              .map((item) =>
                item.eventType === "PAGE_VIEW" ? undefined : item.eventType
              )
              .filter((item) => !!item)
          );

        const _pageViewEvents = response?.pageViewEvents?.map?.((item) => {
          return {
            ...item,
            eventCode: "PAGE_VIEW",
            aimEventName: "PAGE_VIEW",
            count: item.count ?? "1",
          };
        });

        const data = [...(response?.data ?? [])].map((item) => {
          const colorIndex = publisherColors.findIndex((pItem) => {
            return pItem.id === item.publisherID;
          });
          console.log(item.publisherID, " ", colorIndex);
          const color =
            colorIndex >= 0 ? publisherColors[colorIndex].color : "blue";
          const publisherName =
            colorIndex >= 0 ? publisherColors[colorIndex].publisherName : "";
          return item.latitude && item.longitude
            ? {
                data: [item.latitude, item.longitude],
                publisherName,
                color,
                aimEventName:
                  item.eventCode === "PAGE_VIEW"
                    ? "Page View"
                    : item.aimEventName,
                count: item.count ?? "1",
                country: item.country ?? "-",
                publisherID: item.publisherID,
                ...item,
              }
            : undefined;
        });
        setPositions(data.filter((item) => item !== undefined));
      }
    } catch (error) {
      console.log("Unable to get the aim map data");
      console.log(error);
    }
  }

  async function fetchAIMUserData() {
    try {
      const response = await getAIMCalls(
        startDate.format("YYYY-MM-DD"),
        moment(endDate.valueOf()).add(1, "day").format("YYYY-MM-DD"),
        appIDs,
        duration
      );
      if (response.success) {
        setAIMCallsData({
          ...response.data,
          userPerTimeFrameData: response?.userPerTimeFrameData ?? [],
          uniqueUserData: response?.uniqueUserData ?? [],
        });
      }
    } catch (error) {
      console.log(
        "Unable to get the aim maps data for the publisher at the moment"
      );
      console.log(error);
    }
  }

  function calculateRadius(count, minCount, maxCount) {
    const maxRadius = 30;
    const minRadius = 10;
    if (minCount === maxCount) {
      return maxRadius;
    }

    // Apply logarithmic scaling
    const logMinCount = Math.log(minCount);
    const logMaxCount = Math.log(maxCount);
    const logCount = Math.log(count);

    return (
      minRadius +
      ((logCount - logMinCount) * (maxRadius - minRadius)) /
        (logMaxCount - logMinCount)
    );
  }

  function getDurationForUsers() {
    const daysDifference = endDate.diff(startDate, "days");
    if (daysDifference <= 7) {
      return `${daysDifference || 1} Day${daysDifference <= 1 ? "" : "s"}`;
    } else if (daysDifference > 7 && daysDifference < 366) {
      const monthsDifference = endDate.diff(endDate, "month");
      return `${monthsDifference || 1} Month${
        monthsDifference <= 1 ? "" : "s"
      }`;
    } else if (daysDifference >= 366) {
      const yearsDifference = endDate.diff(endDate, "year");
      return `${yearsDifference || 1} Year${yearsDifference <= 1 ? "" : "s"}`;
    }
  }

  function getAuthenticatedAnonymousUsersPercentage() {
    const authUsers = parseInt(aimCallsData?.[0]?.authenticatedCount ?? "0");
    const anonymousUsers = parseInt(aimCallsData?.[0]?.anonymousCount ?? "0");
    const totalCount = authUsers + anonymousUsers;
    const authPercentage = ((authUsers * 100) / totalCount).toFixed(2);
    const anonymousPercentage = ((anonymousUsers * 100) / totalCount).toFixed(
      2
    );
    return {
      authPercentage,
      anonymousPercentage,
    };
  }

  function getDurationForUsersGraph() {
    const daysDifference = endDate.diff(endDate, "days");
    if (daysDifference <= 7) {
      return `Day`;
    } else if (daysDifference > 7 && daysDifference <= 31) {
      return "Week";
    } else if (daysDifference > 31 && daysDifference < 366) {
      return `Month`;
    } else if (daysDifference >= 366) {
      return `Year`;
    }
  }

  /**
   * Format the event name correctly
   *
   * @param {string} event
   */
  function formatEventName(event) {
    switch (event) {
      case "ID5_PIGGY_BACKED":
        return "ID5 JS";
      case "PAGE_VIEW":
        return "Page Views";
      default:
        return event;
    }
  }

  function renderAIMCallsCard() {
    return (
      <div
        style={{
          height: 560,
          width: 460,
          background: "black",
          borderRadius: 16,
          zIndex: 1000,
          padding: 16,
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          bottom: -200,
          left: 128,
        }}
      >
        <span
          style={{
            color: "white",
            fontFamily: "Montserrat",
            fontSize: 18,
          }}
        >
          AIM Calls
        </span>
        {!!uniqueUserCount && !!duration && (
          <>
            <span
              style={{
                color: "white",
                opacity: 0.5,
                fontFamily: "Montserrat",
                fontSize: 14,
                marginTop: 12,
              }}
            >
              Users in last {getDurationForUsers()}
            </span>
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                fontSize: 48,
              }}
            >
              {formatNumberWithCommas(uniqueUserCount)}
            </span>
          </>
        )}
        {
          <>
            <span
              style={{
                color: "white",
                opacity: 0.5,
                fontFamily: "Montserrat",
                fontSize: 14,
                marginTop: 12,
              }}
            >
              Total Server calls in {getDurationForUsers()}
            </span>
            <span
              style={{
                color: "white",
                fontFamily: "Montserrat",
                fontSize: !uniqueUserCount || !duration ? 48 : 20,
              }}
            >
              {formatNumberWithCommas(
                parseInt(aimCallsData?.[0]?.anonymousCount) +
                  parseInt(aimCallsData?.[0]?.authenticatedCount)
              )}
            </span>
          </>
        }
        <span
          style={{
            color: "white",
            opacity: 0.5,
            fontFamily: "Montserrat",
            fontSize: 14,
            marginTop: 12,
          }}
        >
          Users Per {getDurationForUsersGraph()}
        </span>
        <div
          style={{
            height: 100,
            marginTop: 8,
          }}
        >
          <ResponsiveContainer width="100%">
            <BarChart
              width={500}
              height={100}
              data={userGraphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey={"date"} />
              <Bar dataKey="dataCount" fill="#82ca9d" />
              <Tooltip
                cursor={false}
                content={({ active, payload, label }) => {
                  return (
                    <div
                      style={{
                        minWidth: 220,
                        minHeight: 20,
                        background: "#171717",
                        display: "flex",
                        flexDirection: "column",
                        padding: 16,
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          fontSize: 16,
                          fontFamily: "Montserrat",
                        }}
                      >
                        {label}
                      </span>
                      <span
                        style={{
                          color: "white",
                          fontSize: 14,
                          fontFamily: "Montserrat",
                        }}
                      >
                        Users:{" "}
                        {formatNumberWithCommas(
                          payload?.[0]?.payload?.dataCount ?? "0"
                        )}
                      </span>
                    </div>
                  );
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <span
          style={{
            color: "white",
            opacity: 0.5,
            fontFamily: "Montserrat",
            fontSize: 14,
            marginTop: 16,
          }}
        >
          Authenticated Users vs Anonymous Users
        </span>
        <div
          style={{
            marginTop: 10,
          }}
        />
        <div
          style={{
            marginLeft: 20,
          }}
        >
          <PieChart width={120} height={120}>
            <Pie
              data={[
                {
                  name: "Authentcated Users",
                  value: parseInt(aimCallsData?.[0]?.authenticatedCount ?? "0"),
                },
                {
                  name: "Anonymous Users",
                  value: parseInt(aimCallsData?.[0]?.anonymousCount ?? "0"),
                },
              ]}
              stroke="transparent"
              fill="#8884d8"
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={40}
              outerRadius={60}
              paddingAngle={0}
              labelLine={false}
            >
              <Cell key={`cell-${0}`} fill={"#E88D67"} />
              <Cell key={`cell-${1}`} fill={"#2E70D2"} />
            </Pie>
            <Tooltip
              content={({ active, payload, label }) => {
                return (
                  <div
                    style={{
                      minWidth: 360,
                      minHeight: 20,
                      background: "#171717",
                      display: "flex",
                      flexDirection: "column",
                      padding: 16,
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontSize: 16,
                      }}
                    >
                      Authenticated Users:{" "}
                      {formatNumberWithCommas(
                        parseInt(aimCallsData?.[0]?.authenticatedCount ?? "0")
                      )}{" "}
                      (
                      {getAuthenticatedAnonymousUsersPercentage()
                        .authPercentage + "%"}
                      )
                    </span>
                    <span
                      style={{
                        color: "white",
                        fontSize: 16,
                      }}
                    >
                      Anonymous Users:{" "}
                      {formatNumberWithCommas(
                        parseInt(aimCallsData?.[0]?.anonymousCount ?? "0")
                      )}{" "}
                      (
                      {getAuthenticatedAnonymousUsersPercentage()
                        .anonymousPercentage + "%"}
                      )
                    </span>
                  </div>
                );
              }}
            />
          </PieChart>
        </div>
      </div>
    );
  }

  function getCorrectEventTitleForMap(item) {
    if (item.eventType) {
      return item.eventType;
    } else if (item.event && item.event === "AIM Tokens") {
      return item.eventCode.replace("_", " ").replace("AIM", "");
    }
    return item.event;
  }

  return (
    <div
      style={{
        height: "calc(100vh - 280px)",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: -30,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 24,
              fontWeight: "700",
              fontFamily: "Montserrat",
            }}
          >
            AIM Analytics
          </span>
          <span
            style={{
              color: "#8B8B8B",
              fontSize: 14,
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          >
            Last updated {moment().format("hh:mm A")}
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <AppSelectionDropdown
            disabled={false}
            value={appIDs}
            onChange={(appIDs) => {
              setAppIDs(appIDs);
            }}
          />
          <LockrFromToDatePicker
            endDate={endDate}
            onChangeEndDate={setEndDate}
            onChangeStartDate={setStartDate}
            startDate={startDate}
            addCustomDatePicker
            storeForAIMAnalyticsDuration
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 20,
          marginTop: 60,
          justifyContent: "flex-end",
        }}
      >
        <MapEventsDropDown
          eventOptions={menuLoginOptions}
          setSelectedEvent={setSelectedEvent}
          setSupportPageView={setSupportPageViews}
          supportPageView={supportPageViews}
        />
      </div>
      <div
        style={{
          marginTop: 20,
        }}
      />
      <div
        style={{
          height: "calc(100vh - 200px)",
          width: "100%",
          backgroundColor: "black",
          position: "relative",
        }}
      >
        {positions.length > 0 && (
          <MapContainer
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "black",
              position: "absolute",
            }}
            center={[37.751, -97.822]}
            zoom={2}
            minZoom={2}
            scrollWheelZoom={false}
          >
            <TileLayer url="http://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}" />
            {myPositionsData.map((pItem) => {
              let circleRadius = calculateRadius(
                parseInt(pItem.count),
                minPositionItem,
                maxPositionItem
              );
              return (
                <CircleMarker
                  radius={circleRadius}
                  pathOptions={{
                    color: "#2e70d2",
                  }}
                  center={pItem.data}
                >
                  <Popup>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      <div>
                        {pItem.events.map((eItem) => {
                          return (
                            <div>
                              <span
                                style={{
                                  fontFamily: "Montserrat",
                                  fontSize: 14,
                                  color: "black",
                                }}
                              ></span>
                              {formatEventName(
                                eItem.aimEventName ||
                                  eItem.integrationType ||
                                  eItem.event
                              )}
                              ,{" "}
                              {formatNumberWithCommas(eItem?.eventCount ?? "0")}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Popup>
                </CircleMarker>
              );
            })}
          </MapContainer>
        )}
      </div>
      {renderAIMCallsCard()}
    </div>
  );
}
