import React from "react";
import { Button, Modal } from 'antd';
import { IoMdArrowBack } from 'react-icons/io';
import { identitProvidersType } from "../Utils/Helper";
import { withRouter } from 'react-router-dom';
import Id5 from '../assets/id5_grey_logo.svg'
import Euid from '../assets/euid_grey_logo.svg'

function DataConnectorModal({
    display, onOk, integrationType, logo, history
}) {

    function renderTitles() {
        return (
            <div style={{ marginTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ fontFamily: 'Montserrat', fontSize: 24, color: 'white', fontWeight: '700' }}>Connect lockr with {integrationType}</span>
                <Button
                    style={{ width: 217, marginTop: 24, cursor: 'pointer' }}
                    type="primary"
                    className="sign-in"
                    onClick={onOk}
                >Start Connection</Button>
                <div style={{
                    position: 'absolute',
                    left: 16,
                    top: 16,
                }}>
                    <IoMdArrowBack onClick={() => {
                        history.goBack();
                    }} style={{
                        color: 'white',
                        fontSize: 24,
                        cursor: 'pointer'
                    }} />
                </div>
            </div>
        )
    }

    return (
        <Modal visible={display}
            style={{
                top: 20, paddingBottom: 20,
            }}
            centered width={720} footer={null} closable={false} destroyOnClose
            bodyStyle={{ flexDirection: 'column', minHeight: 480, width: 720, backgroundColor: '#353738', paddingRight: 0, overflowX: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <img
                    alt='logo_grey'
                    src={require('../assets/logo_grey.png')}
                />
                <span style={{ fontFamily: 'Montserrat', fontSize: 20, marginTop: 6, fontWeight: 300, color: '#8B8B8B' }}>+</span>
                <div style={{ marginTop: 8 }}>
                    {logo && <img src={logo} style={{ height: 50, width: 250 }} />}
                </div>
                {renderTitles()}
            </div>
        </Modal>
    )
}

export const DataConnectorCredentialModal = withRouter(DataConnectorModal);