/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import SideDrawer from "../../../Components/SideDrawer";
import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Input,
    Modal,
    Row,
    Select,
    Table,
    Tooltip,
    Typography,
} from "antd";

import { createNewCohort, deleteAWSEndpoint, downloadCSVEndpointData, getAllCohortList, getCohortsAttributes, getCSVEndpointTableData, getS3EndPoints, newS3DataEndPoint, saveCohortUpdate, updateS3DataEndPoint } from "../../../Utils/ApiActions";

import deleteIcon from '../../../assets/delete-white.svg'
import editIcon from '../../../assets/edit.svg'
import propertiesIcon from '../../../assets/properties.svg'
import duplicateIcon from '../../../assets/duplicate-copy.svg'
import eyeIcon from '../../../assets/eye.svg'
import eyeOff from '../../../assets/eye-off.svg'
import dotGridIcon from '../../../assets/dots-grid.svg'

import { AiOutlineCheck, AiOutlineDelete, AiOutlineDown, AiOutlinePlus } from "react-icons/ai";

import { LockrInfoIcon } from "../../../Components/UIDCredentialsModal";
import { isUUID } from "../../../Utils/uuid.helper";
import { DATA_ENDPOINTS } from "../../../Utils/Helper";
import DeleteTypeModal from "../../../Components/LockrDeleteModal";
import { CustomConditionsBuilder } from "./components/CustomConditionsBuilder";
import downLoadIcon from "../../../assets/download.svg"
import threeDotMenu from "../../../assets/dots-vertical.svg"
import checkCircle from "../../../assets/check-circle-green.svg"

export const conditionDropdown = [{
    label: "Is",
    key: 'is'
}, {
    label: "Contains",
    key: 'contains'
},
{
    label: "Starts With",
    key: 'startsWith'
},
{
    label: "Ends With",
    key: 'endsWith'
}, {
    label: "Does not contain",
    key: "doesNotContain",
}, {
    label: "Is Null",
    key: 'isNull'
}]

export const uuidConditionDropdown = [{
    label: "Is",
    key: 'is'
}, {
    label: "Is Null",
    key: 'isNull'
}]

export const dateConditionDropdown = [{
    label: "After",
    key: 'after'
}, {
    label: "Before",
    key: 'before'
}, {
    label: "Between",
    key: 'between'
}]

export const attributesNotToIncudeInDropdown = ["integrationTypeUID", "integrationTypeID5", "integrationTypeRAMPID", "integrationTypeEUID"]


export function DataEndpointTitleValue({
    title,
    value,
    showDropdown = false,
    isEdit = false,
    infoText,
}) {
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    alignItems: "center",
                    marginBottom: 4,
                }}
            >
                <span
                    style={{
                        fontWeight: "500",
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        color: "white",
                    }}
                >
                    {title}
                </span>
                {infoText && (
                    <Tooltip
                        style={{
                            cursor: "pointer",
                        }}
                        title={infoText}
                    >
                        <div>
                            <LockrInfoIcon />
                        </div>
                    </Tooltip>
                )}
            </div>
            <div
                style={{
                    background: "#26282A",
                    minHeight: 50,
                    minWidth: 600,
                    maxWidth: 600,
                    display: "flex",
                    alignItems: "center",
                    marginTop: 4,
                    paddingLeft: 8,
                    position: "relative",
                }}
            >
                <span
                    style={{
                        fontWeight: "500",
                        minWidth: 550,
                        maxWidth: 550,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        color: isEdit ? "white" : "#8B8B8B",
                    }}
                >
                    {value}
                </span>
                {showDropdown && (
                    <AiOutlineDown
                        style={{ color: isEdit ? "white" : "#8B8B8B", position: "absolute", right: 16 }}
                    />
                )}
            </div>
        </div>
    );
}






export function CSVDataEndPoint() {



    const [cohorts, setCohorts] = useState([]);
    const [selectedCohort, setSelectedCohort] = useState({ appliedFilters: [{ config: { condition: 'AND', operand: "", operator: "", type: "", value: "" }, type: "filter" }], selectedAttributes: [] });
    const [currentCohort, setCurrentCohort] = useState({ appliedFilters: [{ config: { condition: 'AND', operand: "", operator: "", type: "", value: "" }, type: "filter" }], selectedAttributes: [] });
    const [previousCohortCurrent, setPreviousCohortCurrent] = useState([])

    const [renameCohort, setRenameCohort] = useState(false);
    const [cohortName, setCohortName] = useState("All Visitors");


    const [propertiesModalVisible, setPropertiesModalVisible] = useState(false)

    const [deleteModal, setDeleteModal] = useState(false)


    const [addNewCohort, setAddNewCohort] = useState(false)
    const [editCohort, setEditCohort] = useState(false)

    const [attributes, setAttributes] = useState([])
    const [tableData, setTableData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [pageSize, setPageSize] = useState(25)

    const [searchQuery, setSearchQuery] = useState("");

    const filteredCohorts = useMemo(() => {
        return cohorts.filter(cohort =>
            cohort.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [cohorts, searchQuery]);

    const getAttributes = async () => {
        const attrs = await getCohortsAttributes()
        setAttributes(attrs.data)
    }
    const getCohortList = async (update = false) => {
        const cohortsData = await getAllCohortList()
        if (!update) {
            setCohortName(cohortsData.data[0]?.name)
            setCurrentCohort(cohortsData.data[0])
            setPreviousCohortCurrent(cohortsData.data[0])
            setCohorts([...cohortsData.data])
        }
        else {
            setCohorts([...cohortsData.data])
        }

    }

    const updateSelectedCohortFilters = (config) => {
        setCurrentCohort({ ...currentCohort, configuration: config })
        console.log({ ...currentCohort, configuration: config })
    }

    const onSaveCohort = async () => {
        const cohortData = {
            name: cohortName,
            configuration: currentCohort.configuration,
            id: currentCohort?.id
        }

        let success = false;
        if (addNewCohort) {
            // Use createCohort API for new cohorts
            const response = await createNewCohort(currentCohort.configuration, cohortName);
            setCurrentCohort(response.data);
            setPreviousCohortCurrent(response.data);
            success = response?.success;
        } else {
            // Use saveCohortUpdate API for existing cohorts
            const response = await saveCohortUpdate(cohortData);
            success = response?.success;
        }

        if (success) {
            setRenameCohort(false);
            setAddNewCohort(false);
            getCohortList(true);
        }
    }



    useEffect(() => {
        getAttributes()
        getCohortList()
    }, [])

    useEffect(() => {
        if (!(editCohort || addNewCohort)) {
            getCSVTableData()
        }
    }, [pageNum, pageSize])
    useEffect(() => {
        if (pageNum === 1) {
            getCSVTableData()
        }
        else {
            setPageNum(1)
        }
    }, [selectedCohort])


    const getCSVTableData = async (force = false) => {
        const filterConditons = []
        selectedCohort?.configuration?.appliedFilters?.filters?.map((c) => {
            if (c.attr && c.conditionKey) {
                if (c.value === false) {
                    filterConditons.push(c)
                }
                else if (c.type === "uuid" && isUUID(c.value)) {
                    filterConditons.push(c)
                }
                else if (c.type !== "uuid" && c.value) {
                    filterConditons.push(c)
                }

            }
        })
        if (((addNewCohort || editCohort) || selectedCohort?.configuration?.selectedAttributes.length === 0) && !force) { return [] }
        const data = await getCSVEndpointTableData({ name: "test", appliedFilters: { allCondition: selectedCohort?.configuration?.appliedFilters.allCondition, filters: selectedCohort?.configuration?.appliedFilters?.filters }, selectedAttributes: selectedCohort?.configuration?.selectedAttributes }, pageNum, pageSize)
        if (data?.success === false) {
            return
        }

        setTableData(data.data)
        setTotalCount(data.count)

    }




    const onAddNewCohort = () => {
        setAddNewCohort(true);
        // Set initial cohort state
        const newCohort = { configuration: { appliedFilters: [{ config: { condition: 'AND', operand: "", operator: "", type: "", value: "" }, type: "filter" }], selectedAttributes: [] } };
        setCurrentCohort(newCohort);
        setPreviousCohortCurrent(newCohort);
        // Turn on rename mode immediately
        setRenameCohort(true);
        setCohortName("All Visitors");
    }

    const onDuplicateCohort = () => {
        setAddNewCohort(true);

        setCurrentCohort(currentCohort);
        setPreviousCohortCurrent(currentCohort);


        setRenameCohort(true);
        setCohortName(`${currentCohort?.name} Duplicate`);
    }

    const onCohortDelete = async () => {
        await deleteAWSEndpoint(selectedCohort?.id);
        getCohortList()
        setDeleteModal(false)
    }

    function renderEmptySection() {
        return (
            <div
                style={{
                    marginLeft: 90,
                    marginRight: 90,
                    minHeight: "50vh",
                    background: "black",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <img alt="logo_grey" src={require("../../../assets/logo_grey.png")} />
                <div
                    style={{
                        marginTop: 42,
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography.Text
                        style={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "24px",
                            color: "#fff",
                        }}
                    >
                        Add a new Cohort for CSV Export
                    </Typography.Text>
                    <Button
                        style={{ marginTop: 24, width: "217px" }}
                        type="primary"
                        className="btn-first-connection"
                        onClick={() => {
                            onAddNewCohort()
                        }}
                    >
                        Add New Cohort
                    </Button>
                </div>
            </div>
        );
    }

    const downloadData = async () => {
        const response = await downloadCSVEndpointData(selectedCohort.configuration)
        const blob = new Blob([response.data], { type: 'text/csv' });

        // Create a temporary link element to trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'data.csv';
        link.click();
    }

    const renderPropertiesModal = () => {
        return <div style={{ background: 'black', height: 309, position: 'absolute', left: -210, top: -50, borderRadius: 8 }}>
            <div onClick={() => {
                console.log(attributes.filter(attribute =>
                    currentCohort.configuration.selectedAttributes.includes(attribute.key)
                ))
                console.log(attributes.filter(attribute =>
                    !currentCohort.configuration.selectedAttributes.includes(attribute.key)
                ))
            }} style={{ padding: 16, paddingBottom: 8, borderBottom: '1px solid #1D1D1D', }}>Properties</div>
            {console.log(currentCohort)}
            <div style={{ padding: '8px 16px', borderBottom: '1px solid #1D1D1D' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 700, color: 'white', width: 160 }}>Shown In Table <div style={{ color: '#61CEF7' }}>3</div></div>
                <div style={{ maxHeight: 80, overflow: 'scroll' }}>
                    {attributes
                        .filter(attribute => currentCohort.configuration.selectedAttributes.includes(attribute.key))
                        .map((a) => (
                            <div
                                key={a.key}
                                style={{ display: 'flex', padding: '6px 0px', alignItems: 'center' }}
                                onClick={() => {
                                    setCurrentCohort(prevCohort => {
                                        let tempCohort = JSON.parse(JSON.stringify(prevCohort));
                                        tempCohort.configuration.selectedAttributes = tempCohort.configuration.selectedAttributes.filter(key => key !== a.key)
                                        return tempCohort;
                                    });
                                }}
                            >
                                <img style={{ marginRight: 4 }} src={dotGridIcon} alt="dot grid" />
                                <div
                                    style={{
                                        width: 88,
                                        overflow: 'hidden',
                                        flex: 1,
                                        whiteSpace: 'nowrap',
                                        fontWeight: 500,
                                    }}
                                >
                                    {a.label}
                                </div>
                                <img src={eyeIcon} alt="eye icon" />
                            </div>
                        ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 700, color: 'white', width: 160 }}>Hidden In Table <div style={{ color: '#61CEF7' }}>3</div></div>
                <div style={{ maxHeight: 80, overflow: 'scroll' }}>
                    {attributes
                        .filter(attribute => !currentCohort.configuration.selectedAttributes.includes(attribute.key))
                        .map((a) => (
                            <div
                                key={a.key}
                                style={{ display: 'flex', padding: '6px 0px', alignItems: 'center' }}
                                onClick={() => {
                                    setCurrentCohort(prevCohort => {
                                        let tempCohort = JSON.parse(JSON.stringify(prevCohort));
                                        tempCohort.configuration.selectedAttributes = [...tempCohort.configuration.selectedAttributes, a.key]
                                        return tempCohort;
                                    });
                                }}
                            >
                                <img style={{ marginRight: 4 }} src={dotGridIcon} alt="dot grid" />
                                <div
                                    style={{
                                        width: 88,
                                        overflow: 'hidden',
                                        flex: 1,
                                        whiteSpace: 'nowrap',
                                        fontWeight: 500,
                                        color: '#8B8B8B',
                                    }}
                                >
                                    {a.label}
                                </div>
                                <img src={eyeOff} alt="eye off" />
                            </div>
                        ))}
                </div>
            </div>
            <div style={{ padding: '8px 16px 0px', display: 'flex', alignItems: 'center', gap: 10 }}>
                <div onClick={() => { console.log(currentCohort); setRenameCohort(false) }} style={{ background: '#353738', color: '#EDEDED', height: 30, width: 65, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', borderRadius: 4 }} >Save</div>
                <div onClick={() => { setPropertiesModalVisible(false) }} style={{ background: '#0000009', color: '#8B8B8B', height: 30, width: 53, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} >Back</div>
            </div>
        </div>
    }



    function disableSaveButton() {
        // Always enable save button when adding new cohort
        if (addNewCohort) {
            return false;
        }

        // Existing logic for other cases
        if ((JSON.stringify({ ...previousCohortCurrent }) === JSON.stringify({ ...currentCohort }))) {
            if (!(cohortName === previousCohortCurrent?.name)) {
                return false;
            }
            return true;
        }

        return false;
    }






    function renderMainSections() {
        return <div
            style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: 90,
                paddingRight: 90,
                position: "relative",
            }}
        >

            <div
                style={{

                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 20,
                    paddingTop: 24,
                    paddingBottom: 24,
                    paddingRight: 20,
                    width: '100%'
                }}
            >


                <div className="csv-downloader-endpoint" style={{ color: 'white', marginTop: 20, }}>
                    {renderCSVEndpointTable()}
                </div>
            </div>
        </div>
        return <></>;
    }



    function renderCSVEndpointTable() {
        return <div className="cohort-table-wrapper" style={{ borderRadius: 12, overflow: "scroll" }}>
            <Table
                scroll={{ x: 'max-content' }}
                className=" cohort-table"
                style={{ overflowWrap: "anywhere", borderRadius: 12 }}
                rowKey={"id"}
                dataSource={(editCohort || addNewCohort) ? [] : tableData}
                columns={
                    [
                        ...currentCohort?.configuration?.selectedAttributes ?? []
                    ]
                        .reduce((acc, s) => {
                            const attribute = attributes?.find(a => a.key === s);

                            // Check for integration type keys, and add the "Integration Type" column only once
                            if (
                                attribute?.key === 'integrationTypeUID' ||
                                attribute?.key === 'integrationTypeID5' ||
                                attribute?.key === 'integrationTypeRAMPID' ||
                                attribute?.key === 'integrationTypeEUID'

                            ) {
                                // Check if "Integration Type" is already added to the columns
                                if (!acc.some(col => col.key === 'integrationType')) {
                                    acc.push({
                                        title: "Integration Type",
                                        key: "integrationType",
                                        dataIndex: "integrationType",
                                        width: "max-content",
                                    });
                                    acc.push({
                                        title: "Token",
                                        key: "token",
                                        dataIndex: "token",
                                        width: "max-content",
                                    });
                                }
                            } else {
                                // Add normal column
                                acc.push({
                                    title: attribute?.label,
                                    key: attribute?.key,
                                    dataIndex: attribute?.key,
                                    width: "max-content",
                                });
                            }
                            return acc;
                        }, []) ?? []
                }
                pagination={false}
            >
            </Table>
        </div>
    }

    return (
        <div style={{ fontFamily: 'Montserrat', }}>
            <SideDrawer background={'#1D1D1D'}>
                <Row
                    justify="space-between"
                    align="middle"
                    className="apiandservices-newapp-btn"
                >
                    <Col sm={17} xs={24}>
                        <Typography.Title className="apiandservices-heading">
                            Segments
                        </Typography.Title>
                        <div style={{ marginTop: 8 }} />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <label
                                style={{
                                    fontSize: 14,
                                    fontFamily: "Montserrat",
                                    color: "#8B8B8B",
                                }}
                                className="dashboard-as-of-today"
                            >
                                Seamlessly export audience data to CSV for downstream use-cases.
                            </label>
                        </div>
                    </Col>

                </Row>
                <Row justify="space-between"
                    align="middle"
                    style={{ marginTop: 0, marginBottom: 0 }}
                    className="apiandservices-newapp-btn">
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Dropdown overlay={!renameCohort ?
                            <div style={{ background: 'black', borderRadius: 8, padding: '16px 6px', color: 'white', width: 260 }}>
                                <div style={{ paddingLeft: '10px', marginBottom: 8, fontSize: 14, fontWeight: 700 }}>Segments</div>
                                <input
                                    style={{
                                        height: 31,
                                        padding: '0 10px',
                                        background: 'transparent',
                                        border: '1px solid #26282A',
                                        borderRadius: 4,
                                        width: '100%',
                                        color: 'white'
                                    }}
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />

                                <div style={{ marginTop: 8, maxHeight: 200, overflowY: 'auto' }}>
                                    {filteredCohorts.map((cohort) => (
                                        <div
                                            key={cohort.id}
                                            style={{
                                                padding: '10px 14px',
                                                cursor: 'pointer',
                                                backgroundColor: currentCohort?.id === cohort.id ? '#353738' : 'transparent',
                                                transition: 'background-color 0.2s'
                                            }}
                                            onClick={() => {
                                                setCurrentCohort(cohort);
                                                setCohortName(cohort.name);
                                            }}
                                        >
                                            {cohort.name}
                                        </div>
                                    ))}
                                    {filteredCohorts.length === 0 && (
                                        <div style={{ padding: '10px 14px', color: '#8B8B8B' }}>
                                            No cohorts found
                                        </div>
                                    )}
                                </div>
                                <div
                                    onClick={() => onAddNewCohort()}
                                    style={{
                                        marginTop: 16,
                                        marginLeft: 12,
                                        background: "#353738",
                                        borderRadius: 4,
                                        height: 30,
                                        width: 154,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#EDEDED',
                                        fontSize: 12,
                                        fontWeight: 600,
                                        cursor: 'pointer'
                                    }}
                                >
                                    Create New Cohort
                                </div>
                            </div>
                            : <></>}
                        >
                            {renameCohort ?
                                <input
                                    onChange={(e) => setCohortName(e.target.value)}
                                    value={cohortName}
                                    style={{
                                        minWidth: 400,
                                        padding: 8,
                                        background: 'transparent',
                                        color: 'white',
                                        border: '1px solid #8B8B8B'
                                    }}
                                    placeholder="Cohort Name"
                                />
                                :
                                <div style={{
                                    fontSize: 20,
                                    fontWeight: 700,
                                    color: 'white',
                                    display: 'flex',
                                    gap: 8,
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}>
                                    {cohortName}
                                    <AiOutlineDown stroke={2} color="#8B8B8B" size={15} />
                                </div>
                            }
                        </Dropdown>
                        <div style={{ display: 'flex', gap: 8 }}>
                            <Dropdown trigger={["click"]} placement="bottomLeft" overlay={<div style={{ height: 261, width: 160, background: 'black', borderRadius: 8, color: 'white', }}>
                                <div style={{ padding: 16, paddingBottom: 8, borderBottom: '1px solid #353738', fontWeight: 700, fontSize: 14 }}>
                                    Export Fields
                                </div>
                                <div style={{ padding: '8px 16px', fontSize: 12, fontWeight: 700, borderBottom: '1px solid #353738', display: 'flex', alignItems: 'center', gap: 8 }}>
                                    <Checkbox
                                        className="custom-checkbox-background"

                                        onChange={(event) => {

                                        }}

                                        checked={true}
                                    />All Fields
                                </div>

                                <div style={{ height: 105, overflowY: 'scroll', padding: '8px 16px', fontSize: 12, display: 'flex', gap: 8, flexDirection: 'column' }}>
                                    {attributes.map(a => <><div style={{ display: 'flex', alignItems: 'center', fontWeight: 500, gap: 8 }}><Checkbox
                                        className="custom-checkbox-background"

                                        onChange={(event) => {

                                        }}
                                        checked={true}
                                    />{a.label}</div></>)}
                                </div>
                                <div style={{ padding: '16px 16px', }}>
                                    <div style={{ height: 30, width: 106, padding: '4px 16px', borderRadius: 4, fontSize: 12, fontWeight: 700, cursor: 'pointer', background: '#353738', color: '#EDEDED', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        Export CSV
                                    </div>
                                </div>
                            </div>}>
                                <div style={{ height: 44, width: 44, padding: 10, background: '#26282A', border: 'border: 1px solid #26282A', borderRadius: 2 }}>
                                    <img src={downLoadIcon} style={{ height: 24, width: 24 }}></img>
                                </div>
                            </Dropdown>
                            <Dropdown trigger={["click"]} overlay={<div style={{ width: 160, background: 'black', borderRadius: 8, color: 'white', cursor: 'pointer' }}>
                                <div style={{ padding: 16, paddingBottom: 8, borderBottom: '1px solid #353738', fontWeight: 700, fontSize: 14, color: disableSaveButton() ? '#8B8B8B' : '#50E8B1' }}>
                                    <div
                                        onClick={() => {
                                            if (!disableSaveButton()) {

                                                setRenameCohort(false);
                                                onSaveCohort();
                                            }
                                        }}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 8,
                                            cursor: disableSaveButton() ? 'not-allowed' : 'pointer'
                                        }}
                                    >
                                        <img src={checkCircle} />Save
                                    </div>
                                </div>
                                <div style={{ padding: '8px 16px', borderBottom: '1px solid #353738', fontSize: 12, fontWeight: 700 }}>
                                    <div onClick={() => {
                                        setRenameCohort(true)
                                    }} style={{ marginBottom: 12, display: 'flex', alignItems: 'center', gap: 8 }}><img src={editIcon} />Rename</div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }} onClick={() => onDuplicateCohort()}><img src={duplicateIcon} />Duplicate</div>
                                </div>
                                <div style={{ padding: '8px 16px', borderBottom: '1px solid #353738', fontSize: 12, fontWeight: 700, display: 'flex', alignItems: 'center', gap: 8 }}>

                                    <div onClick={() => setPropertiesModalVisible(!propertiesModalVisible)} style={{ display: 'flex', alignItems: 'center', gap: 8, position: 'relative' }}>
                                        <img src={propertiesIcon} />Edit Properties
                                        {propertiesModalVisible && renderPropertiesModal()}

                                    </div>
                                </div>
                                <div style={{ padding: '8px 16px', fontSize: 12, fontWeight: 700 }}>

                                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}><img src={deleteIcon} />Delete</div>
                                </div>
                            </div>}>
                                <div style={{ height: 44, width: 44, padding: 10, background: '#26282A', border: 'border: 1px solid #26282A', borderRadius: 2 }}>
                                    <img style={{ height: 24, width: 24 }} src={threeDotMenu}></img>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </Row>
                <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginTop: 20 }}
                    className="apiandservices-newapp-btn"
                >
                    {console.log(currentCohort)}
                    <CustomConditionsBuilder selectedCohort={JSON.parse(JSON.stringify(currentCohort))} attributes={attributes} editEnabled={true} updateSelectedCohortConditions={updateSelectedCohortFilters} updateSelectedCohortFilters={updateSelectedCohortFilters} />

                </Row>

                {/* {renderEmptySection()} */}
                {(cohorts.length > 0 || addNewCohort) ? renderMainSections() : renderEmptySection()}
                {deleteModal && <DeleteTypeModal visible={deleteModal} handleOk={() => { onCohortDelete() }} handleCancel={() => setDeleteModal(false)} title="Delete Cohort" message={"Are you sure you want to delete this Cohort"} confirmText={"Please confirm by typing DELETE into the field below:"} />}
            </SideDrawer>
        </div>
    );
}
