/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import SideDrawer from "../../../Components/SideDrawer";
import { Col, Row, Switch, Typography } from "antd";
import { IdentityProviderGraphStrip } from "../components/IdentityProviderlockrGraphStrip";
import { IdentityProviderStatsAuthStrip } from "../components/IdentityProviderStatsAuthStrip";
import moment from "moment";
import {
  getDeletedUIDCredentialsAPI,
  getUIDCredentialsAPI,
  getUIDUserGraphData,
  removeUIDIntegration,
  upsertUIDCredentialsAPI,
} from "../../../Utils/ApiActions";
import { identitProvidersType } from "../../../Utils/Helper";
import { UIDCredentialsModal } from "../../../Components/UIDCredentialsModal";
import { UIDNoCredentialModal } from "../../../Components/UIDNoCredentialModal";

export function RampJSToken() {
  const [rampJSCredentials, setRampJSCredentials] = React.useState(undefined);
  const [dispalyLoading, setDisplayLoading] = React.useState(false);
  const [userAuthenticationData, setUserAuthenticationData] =
    useState(undefined);
  const [userAuthDataStartDate, setUserAuthDataStartDate] = useState(
    moment().subtract(7, "days")
  );
  const [displayCredentialsModal, setDisplayCredentialsModal] = useState(false);
  const [userAuthDataEndDate, setUserAuthDataEndDate] = useState(moment());

  const [graphFilterStartDate, setGraphFilterStartDate] = useState(
    moment().subtract(7, "days")
  );
  const [graphData, setGraphData] = useState([]);
  const [graphFilterEndDate, setGraphFilterEndDate] = useState(moment());
  const [authDataAppIDs, setAuthDataAppIDs] = useState([]);
  const [loginTrendsAppIDs, setLoginTrendsAppIDs] = useState([]);
  const [deletedCredentials, setDeletedCredentials] = useState(undefined);

  const ssoUserCount = useMemo(() => {
    if (userAuthenticationData?.length) {
      let result = 0;
      userAuthenticationData.forEach((item) => {
        if (item.eventType === "lockrMail") {
          result = parseInt(item.count);
        }
      });
      return result;
    }
    return 0;
  }, [userAuthenticationData]);

  const utmUserCount = useMemo(() => {
    if (userAuthenticationData?.length) {
      let result = 0;
      userAuthenticationData.forEach((item) => {
        if (item.eventType === "UTM") {
          result = parseInt(item.count);
        }
      });
      return result;
    }
    return 0;
  }, [userAuthenticationData]);

  const emailCount = useMemo(() => {
    if (userAuthenticationData?.length) {
      let result = 0;
      userAuthenticationData.forEach((item) => {
        if (item.eventType !== "UTM" && item.eventType !== "lockrMail") {
          result += parseInt(item.count);
        }
      });
      return result;
    }
    return 0;
  }, [userAuthenticationData]);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    });
    getRampIDCredentials();
  }, []);

  async function getGraphData() {
    try {
      const { data, success } = await getUIDUserGraphData(
        graphFilterStartDate,
        graphFilterEndDate,
        identitProvidersType.RAMPJS,
        loginTrendsAppIDs
      );
      if (success) {
        setGraphData(data);
      }
    } catch (error) {
      console.log("Unable to get the graph data ", error);
    }
  }

  async function getRampIDCredentials() {
    setDisplayLoading(true);
    try {
      const { data, success } = await getUIDCredentialsAPI(
        identitProvidersType.RAMPJS
      );
      if (success && data?.accessKey) {
        setRampJSCredentials(data);
      } else {
        //Get the deleted credentials for the ramp id js
        getDeletedCredentials();
      }
    } catch (error) {
      console.log("Unable to get the ramp ID JS credentials ", error);
    }
  }

  async function getDeletedCredentials() {
    setDisplayLoading(true);
    try {
      const { data, success } = await getDeletedUIDCredentialsAPI(
        identitProvidersType.RAMPJS
      );
      if (success && data?.accessKey) {
        setDeletedCredentials({ ...data, isDeleted: false });
      }
    } catch (error) {
      console.log(
        "Unable to get the deleted credentials for the ramp ID JS ",
        error
      );
    }
    setDisplayLoading(false);
  }

  async function saveRampJSCredentials(credentials) { 
    setDisplayLoading(true);
    try{
        await upsertUIDCredentialsAPI(
            credentials.accessKey,
            identitProvidersType.RAMPJS,
            identitProvidersType.RAMPJS,
            false
        )
        getRampIDCredentials();
        setDisplayCredentialsModal(false);
    }catch(error) {
        console.log('Unable to save the ramp ID JS credentials ', error);    
    }
    setDisplayLoading(false);
  }

  function displayCredModal() {
    if (displayCredentialsModal) {
      return (
        <UIDCredentialsModal
          integrationType={identitProvidersType.RAMPJS}
          display={displayCredentialsModal}
          credentials={{
            accessKey:
              rampJSCredentials?.accessKey ?? deletedCredentials?.accessKey,
            secretKey:
              rampJSCredentials?.secretKey ?? deletedCredentials?.secretKey,
            useLockrKeys:
              rampJSCredentials?.useLockrKeys ??
              deletedCredentials?.useLockrKeys ??
              false,
          }}
          onOk={saveRampJSCredentials}
          onCancel={() => setDisplayCredentialsModal(false)}
        />
      );
    }
  }

  async function removeRampIDIntegrationCall() {
    try{
        const response = await removeUIDIntegration(identitProvidersType.RAMPJS);
        if(response.success && response.data){
            setDeletedCredentials({
                accessKey: rampJSCredentials.accessKey,
                secretKey: identitProvidersType.RAMPJS,
            })
            setRampJSCredentials(undefined);
        }
    }catch(error) {
        console.log('Unable to remove the ramp ID JS integration ', error);
    }
  }

  function displaySwitch() {
    return (
      <div>
        <Switch
          checked={rampJSCredentials !== undefined}
          onChange={(checked) => {
            !checked && removeRampIDIntegrationCall();
          }}
        />
        <Typography.Text
          onClick={() => {
            setDisplayCredentialsModal(true);
          }}
          className="infosum-view-credentials-text"
        >
          View Credentials
        </Typography.Text>
      </div>
    );
  }

  function displayConnectModal() {
    if(rampJSCredentials === undefined && !displayCredentialsModal && !dispalyLoading) {
        return (
            <UIDNoCredentialModal 
                integrationType = {identitProvidersType.RAMPJS}
                onOk={() => setDisplayCredentialsModal(true)}
                display={true}
            />
        )
    }
    return <></>;
  }

  function renderStats() {
    return (
      <div
        style={{
          marginTop: 72,
          marginLeft: 90,
          marginRight: 90,
        }}
      >
        <IdentityProviderStatsAuthStrip
          utmCount={utmUserCount}
          ssoCount={ssoUserCount}
          emailCount={emailCount}
          allData={userAuthenticationData}
          startDate={userAuthDataStartDate}
          endDate={userAuthDataEndDate}
          onChangeEndDate={setUserAuthDataEndDate}
          onChangeStartDate={setUserAuthDataStartDate}
          setAuthDataAppIDs={setAuthDataAppIDs}
          authDataAppIDs={authDataAppIDs}
        />
        {/* <div style={{ marginTop: 60 }} />
          <IdentityProviderlockrNMailStrip
            startDate={startDate}
            endDate={endDate}
            onChangeEndDate={setEndDate}
            onChangeStartDate={setStartDate}
            consentedPercentage={getConsentedUserPercent()}
            totalUsers={userCount}
            avgEmails={getAvgConnectedEmails()}
            lockrMailAppIDs={lockrMailAppIDs}
            setLockrMailAppIDs={setLockrMailAppIDs}
          /> */}
        <div style={{ marginTop: 60 }} />
        <IdentityProviderGraphStrip
          endDate={graphFilterEndDate}
          startDate={graphFilterStartDate}
          graphData={graphData}
          onChangeEndDate={setGraphFilterEndDate}
          onChangeStartDate={setGraphFilterStartDate}
          setLoginTrendsAppIDs={setLoginTrendsAppIDs}
          loginTrendsAppIDs={loginTrendsAppIDs}
        />
      </div>
    );
  }

  return (
    <SideDrawer>
      <Row
        justify="space-between"
        align="middle"
        className="apiandservices-newapp-btn"
      >
        <Col sm={12} xs={24}>
          <Typography.Title className="apiandservices-heading">
            LiveRamp JS
          </Typography.Title>
          <div style={{ marginTop: 8 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <label
              style={{
                fontSize: 14,
                fontFamily: "Montserrat",
                color: "#8B8B8B",
              }}
              className="dashboard-as-of-today"
            >
              Enable to start a connection
            </label>
            <div
              style={{
                flex: 1,
                marginLeft: 20,
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ flex: 1 }}>{displaySwitch()}</div>
            </div>
          </div>
        </Col>
      </Row>
      {renderStats()}
      {displayConnectModal()}
      {displayCredModal()}
    </SideDrawer>
  );
}
