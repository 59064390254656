import { Button, Col, Grid, Input, Row, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import SideDrawer from "../../Components/SideDrawer";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { checkConnectionsTileActive } from "../../Utils/ApiActions";

export function TickIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
    >
      <path
        d="M11.575 3.19998L10.175 1.79998C10.1282 1.75417 10.0654 1.72852 9.99998 1.72852C9.93454 1.72852 9.87171 1.75417 9.82498 1.79998L3.92498 7.69998C3.87825 7.74579 3.81542 7.77144 3.74998 7.77144C3.68454 7.77144 3.62171 7.74579 3.57498 7.69998L2.17498 6.29998C2.12825 6.25417 2.06542 6.22852 1.99998 6.22852C1.93454 6.22852 1.87171 6.25417 1.82498 6.29998L0.42498 7.69998C0.379173 7.74671 0.353516 7.80954 0.353516 7.87498C0.353516 7.94042 0.379173 8.00325 0.42498 8.04998L3.57498 11.2C3.62171 11.2458 3.68454 11.2714 3.74998 11.2714C3.81542 11.2714 3.87825 11.2458 3.92498 11.2L11.575 3.54998C11.6208 3.50325 11.6464 3.44042 11.6464 3.37498C11.6464 3.30954 11.6208 3.24671 11.575 3.19998Z"
        fill="#6AE5E1"
      />
    </svg>
  );
}

export function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M11.6645 6.30303C11.6645 9.48468 9.16532 12.0346 6.11798 12.0346C3.07063 12.0346 0.571429 9.48468 0.571429 6.30303C0.571429 3.12138 3.07063 0.571429 6.11798 0.571429C9.16532 0.571429 11.6645 3.12138 11.6645 6.30303Z"
        stroke="white"
        stroke-width="1.14286"
        stroke-linecap="round"
      />
      <path
        d="M10.3526 10.1819L16 16.0001"
        stroke="white"
        stroke-width="1.14286"
        stroke-linecap="round"
      />
    </svg>
  );
}

function AddMoreConnectionTile(props) {
  return (
    <div style={{ flex: 1, justifyContent: "flex-end", display: "flex" }}>
      <div
        style={{
          width: 320,
          height: 240,
          background: "#26282A",
          padding: 32,
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <img
          style={{ position: "absolute", right: 0, bottom: 0, zIndex: 0 }}
          alt="network-back"
          src={require("../../assets/more_connection_back.png")}
        />
        <Typography.Text
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: 16,
            zIndex: 2,
            color: "#fff",
          }}
        >
          We’re adding more connections regularly
        </Typography.Text>
        <Typography.Text
          className="more-connection-description-text"
          style={{
            marginTop: 10,
            fontFamily: "Montserrat",
            fontWeight: "400",
            maxWidth: 168,
            zIndex: 2,
            fontSize: 12,
          }}
        >
          Let us know if you have a connection request that you’d like lockr to
          make available?
        </Typography.Text>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-end",
          }}
        >
          <Button
            disabled
            style={{
              height: 40,
              width: 208,
              background: "black",
              border: "1px solid black",
              marginTop: 16,
            }}
          >
            <Typography.Text
              className="font-twelve"
              style={{
                fontWeight: "700",
                color: "white",
                fontFamily: "Montserrat",
              }}
            >
              Request a connection
            </Typography.Text>
          </Button>
        </div>
      </div>
    </div>
  );
}

function ConnectionTileButton(props) {
  return (
    <Button
      {...props}
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: 35,
        marginTop: 16,
        border: props.isActive
          ? "1px solid #6AE5E1"
          : props.disabled
            ? "1px solid #8B8B8B"
            : "1px solid #ffffff",
        background: "transparent",
        paddingTop: 6,
        paddingBottom: 0,
      }}
    >
      {props.isActive && (
        <div style={{ marginTop: 0.5 }}>
          <TickIcon />
        </div>
      )}
      <label
        className="font-twelve"
        style={{
          cursor: "pointer",
          color: props.isActive
            ? "#6AE5E1"
            : props.disabled
              ? "#8B8B8B"
              : "white",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: 12,
          marginLeft: props.isActive ? 10 : 0,
        }}
      >
        {props.isActive ? "Active" : props.label}
      </label>
    </Button>
  );
}

function ConnectionTile(props) {
  const history = useHistory();
  const { name, searchText } = props;

  function getTopImageSrc() {
    switch (props.type) {
      case "INFOSUM":
        return require("../../assets/infosum_logo.png");
      case "SELF_SERVE":
        return require("../../assets/self_serve_logo.png");
      case "AWS":
        return require("../../assets/aws_logo.png");
      case "THE_TRADE_DESK":
        return require("../../assets/trade_desk_logo.png");
      case "MPARTICLE":
        return require("../../assets/mparticle.png");
      case "UID":
        return require("../../assets/uid2_token.png");
      case "SEGMENT":
        return require("../../assets/segment_icon.png");
      case "LIVERAMP":
        return require("../../assets/liveramp_logo.png");
      case "ID5":
        return require("../../assets/id5_logo.png");
      case "EXPERIAN":
        return require("../../assets/experian_logo.png");
      case "OKTA":
        return require("../../assets/okta_logo.png");
      case "PIANO":
        return require("../../assets/piano_logo.jpeg");
      case "STYTCH":
        return require("../../assets/stytch_logo.jpeg");
      case "ZEPHR":
        return require("../../assets/zuora_logo.png");
      case "OPTABLE":
        return require("../../assets/optable_logo.jpeg");
      case "BLUECONIC":
        return require("../../assets/blueconic_logo.jpeg");
      case "TEALIUM":
        return require("../../assets/tealium_logo.png");
      case "GOOGLE":
        return require("../../assets/google_platform_logo.png");
      case "YAHOO":
        return require("../../assets/yahoo_logo.jpg");
      case "CRITEO":
        return require("../../assets/criteo_logo.png");
      case "GCP":
        return require("../../assets/gcp_logo.png");
      case "SYMITRI":
        return require("../../assets/symitri_logo.png");
      case "FIRSTID":
        return require("../../assets/first_id_logo.png");
      case "INDEX_EXCHANGE":
        return require("../../assets/index_exchange_logo.png");
      case "AZURE":
        return require("../../assets/azure.png");
      case "LOTAME":
        return require("../../assets/lotame_logo.png");
      case "ARCSPAN":
        return require("../../assets/arcspan_logo.jpg");
      case "ILLUMA":
        return require("../../assets/illuma_logo.png");
      case "TRANS_UNION":
        return require("../../assets/trans_uninon_logo.png");
      case "AQFER":
        return require("../../assets/aqfer_logo.png");
      case "BOMBORA":
        return require("../../assets/bombora_logo.png");
      case "GOOGLE_AD_MANAGER":
        return require("../../assets/google_ad_manager.png");
      case "EUID":
        return require("../../assets/EUID_logo.webp");
      case "CSV":
        return require("../../assets/image-csv-icon.png")
      case "SNOWFLAKE":
      default:
        return require("../../assets/snowflake_logo.png");
    }
  }

  function getSubTitle() {
    switch (props.type) {
      case "GOOGLE_AD_MANAGER":
        return (
          <img
            style={{
              position: "absolute",
              bottom: 8,
              width: 100,
            }}
            alt={"mad-connect"}
            src={require("../../assets/powered_mad_connection.png")}
          />
        );
      case "THE_TRADE_DESK":
        return (
          <img
            style={{
              marginTop: 11,
              marginBottom: -18,
            }}
            alt={"mad-connect"}
            src={require("../../assets/powered_mad_connection.png")}
          />
        );
      case "SEGMENT":
      case "MPARTICLE":
        return (
          <img
            style={{
              marginTop: 4,
            }}
            alt={"mad-connect"}
            src={require("../../assets/powered_mad_connection.png")}
          />
        );
      case "SELF_SERVE":
        return (
          <img
            style={{
              marginTop: 4,
              height: 21,
              width: 58,
            }}
            alt={"self-serve"}
            src={require("../../assets/self_serve_subtitle.png")}
          />
        );
      case "LIVERAMP":
        return (
          <span
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 700,
            }}
            className="fontSize18"
          >
            {props.imageSubTitle}
          </span>
        )
      default:
        return (
          <label
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "14px",
              marginTop: props.type === "ARCSPAN" ? 10 : 0,
            }}
          >
            {props.imageSubTitle}
          </label>
        );
    }
  }

  function getImageStyle() {
    if (props.type === "SELF_SERVE") {
      return { width: 46 };
    } else if (props.type === "GOOGLE_AD_MANAGER") {
      return { width: 226, marginTop: -22 }
    } else if (props.type === "SEGMENT") {
      return { width: 120 };
    } else if (props.type === "LIVERAMP") {
      return { width: 164, marginTop: 20, };
    } else if (props.type === "ZEPHR") {
      return { width: 164 };
    } else if (props.type === "EUID") {
      return { height: 62 }
    } else if (
      props.type === "LOTAME" ||
      props.type === "ARCSPAN" ||
      props.type === "ILLUMA"
    ) {
      return { width: 144 };
    } else if (props.type === "ID5") {
      return { width: 104 };
    } else if (
      props.type === "EXPERIAN" ||
      props.type === "BLUECONIC" ||
      props.type === "TRANS_UNION"
    ) {
      return { width: 124 };
    } else if (
      props.type === "OKTA" ||
      props.type === "STYTCH" ||
      props.type === "INDEX_EXCHANGE" ||
      props.type === "SYMITRI" ||
      props.type === "FIRSTID"
    ) {
      return { width: 130 };
    } else if (props.type === "PIANO") {
      return { width: 106 };
    } else if (props.type === "OPTABLE" || props.type === "AQFER") {
      return { width: 106 };
    } else if (props.type === "TEALIUM" || props.type === "AZURE") {
      return { width: 126 };
    } else if (
      props.type === "GOOGLE" ||
      props.type === "BOMBORA" ||
      props.type === "YAHOO" ||
      props.type === "CRITEO" ||
      props.type === "GCP"
    ) {
      return { width: 136 };
    } else if (props.type === "CSV") {
      return { width: 60 }
    }
    else {
      return {};
    }
  }

  if (name?.toLowerCase?.()?.includes?.(searchText?.toLowerCase?.())) {
    return (
      <div
        style={{
          minWidth: 275,
          maxWidth: 275,
          paddingLeft: 8,
          paddingRight: 8,
          paddingBottom: 8,
        }}
      >
        <div
          style={{
            display: props.isHidden ? "none" : "block",
            height: 196,
            paddingBottom: 16,
            background: "#000",
          }}
        >
          <div
            style={{
              marginLeft: 4,
              marginRight: 4,
              paddingTop: 4,
            }}
          >
            <div
              style={{
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "white",
                position: "relative",
                flexDirection: "column",
                padding: 16,
              }}
            >
              <img
                alt="integration_logo"
                src={getTopImageSrc()}
                style={getImageStyle()}
              />
              {props.supportEmailConnector && <img
                alt="email_icon"
                src={require('../../assets/lockr_email_icon.png')}
                style={{
                  position: 'absolute',
                  right: 6,
                  top: 6,
                  height: 16,
                  width: 16,
                }}
              />}
               {props.supportBatchConnector && <img 
                alt = "Batch Icon"
                src = {require('../../assets/self_serve_subtitle.png')}
                style={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  // height: 20,
                  width: 28,
                }}
              />}
              {getSubTitle()}
            </div>
          </div>
          <div
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 20,
              display: "flex",
              flexDirection: "column",
              height: 83,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexGrow: 1,
                paddingBottom: 24,
                width: "100%",
              }}
            >
              <ConnectionTileButton
                isActive={props.isActive}
                disabled={props.disableButton}
                label={props.buttonLabel}
                onClick={() => {
                  history.push(props.clickUrl);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

ConnectionTile.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageSubTitle: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonType: PropTypes.string.isRequired,
  clickUrl: PropTypes.string.isRequired,
  disableButton: PropTypes.bool,
  name: PropTypes.string.isRequired,
  searchText: PropTypes.string,
  isActive: PropTypes.bool,
};

function ConnectionTileTypeSelector({ title, isSelected, onPress }) {
  return (
    <div
      onClick={onPress}
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          background: isSelected ? "#61CEF7" : "#353738",
          width: "2px",
          height: "34px",
          cursor: "pointer",
        }}
      />
      <span
        style={{
          fontSize: 14,
          fontWeight: "400",
          fontFamily: "Montserrat",
          color: isSelected ? "white" : "#8B8B8B",
          marginLeft: "10px",
          cursor: "pointer",
        }}
      >
        {title}
      </span>
    </div>
  );
}

ConnectionTileTypeSelector.propTypes = {
  title: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onPress: PropTypes.func,
};

function Connections(props) {
  const [selectedCategory, setSelectedCategory] = useState(0); //0 for Show all, so on
  const [searchText, setSearchText] = useState("");
  const [activityData, setActivityData] = useState(undefined);

  const displayDataConnectors = useMemo(() => {
    const names = [
      "lockr self serve",
      "snowflake",
      "infosum",
      "aws",
      "bombora",
      "arcspan",
      "blueconic",
      "illuma",
      "experian",
      "transunion",
      "aqfer",
    ];
    let result = false;
    names.forEach((nameItem) => {
      if (nameItem.toLowerCase().includes(searchText.toLowerCase())) {
        result = true;
      }
    });
    return result;
  }, [searchText]);

  const displayAuthServices = useMemo(() => {
    const names = ["okta", "piano", "stytch", "zuora"];
    let result = false;
    names.forEach((nameItem) => {
      if (nameItem.toLowerCase().includes(searchText.toLowerCase())) {
        result = true;
      }
    });
    return result;
  }, [searchText]);

  const displayDataEndPoints = useMemo(() => {
    const names = [
      "aws",
      "snowflake",
      "segment",
      "optable",
      "blueconic",
      "tealium",
      "piano",
      "google cloud platform",
      "symitri",
      "index exchange",
      "azure",
      "lotame",
      "google ad manager",
    ];
    let result = false;
    names.forEach((nameItem) => {
      if (nameItem.toLowerCase().includes(searchText.toLowerCase())) {
        result = true;
      }
    });
    return result;
  }, [searchText]);

  const displayIdentityProviders = useMemo(() => {
    const names = ["uid", "liveramp", "id5", "yahoo", "criteo", "firstid", "lotame panorama id"];
    let result = false;
    names.forEach((nameItem) => {
      if (nameItem.toLowerCase().includes(searchText.toLowerCase())) {
        result = true;
      }
    });
    return result;
  }, [searchText]);

  useEffect(() => {
    fetchActivityData();
  }, []);

  async function fetchActivityData() {
    try {
      const connectionActivity = await checkConnectionsTileActive();
      if (connectionActivity.success) {
        setActivityData(connectionActivity.data);
      }
    } catch (error) {
      console.log("Unable to get the activity data at the moment");
      console.log(error);
    }
  }

  function renderDataEndpoints() {
    if (
      (selectedCategory === 0 || selectedCategory === 4) &&
      displayDataEndPoints
    ) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <div style={{ flexDirection: "column", display: "flex" }}>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontStyle: "normal",
                color: "white",
                fontWeight: "700",
              }}
            >
              Data Endpoints
            </span>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontStyle: "normal",
                color: "#8B8B8B",
                fontWeight: "400",
              }}
            >
              It’s your audience; store it where it makes sense for your
              business with these data endpoints.
            </span>
          </div>
          <Row
            style={{
              display: "flex",
              marginTop: 24,
              flexWrap: "wrap",
              gap: 20,
            }}
          >
            <ConnectionTile
              imageSubTitle=""
              type="AWS"
              buttonLabel="Configure"
              subTitle=""
              clickUrl={"/console/aws-data-endpoint"}
              name="aws"
              searchText={searchText}
              title={""}
            />
            <ConnectionTile
              clickUrl={"/console/snowflake-data-endpoint"}
              imageSubTitle=""
              type="SNOWFLAKE"
              buttonLabel="Configure"
              subTitle=""
              name="snowflake"
              searchText={searchText}
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              type="GCP"
              buttonLabel="Coming Soon"
              name="google cloud platform"
              searchText={searchText}
              subTitle=""
              disableButton
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              type="AZURE"
              buttonLabel="Coming Soon"
              name="azure"
              searchText={searchText}
              subTitle=""
              disableButton
              title={""}
            />
            <ConnectionTile
              clickUrl="/console/optable-data-endpoint"
              imageSubTitle=""
              type="OPTABLE"
              buttonLabel="Configure"
              subTitle=""
              name="optable"
              searchText={searchText}
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              type="BLUECONIC"
              name="blueconic"
              searchText={searchText}
              buttonLabel="Coming Soon"
              subTitle=""
              disableButton
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              type="TEALIUM"
              name="tealium"
              searchText={searchText}
              buttonLabel="Coming Soon"
              subTitle=""
              disableButton
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              type="INFOSUM"
              buttonLabel="Coming Soon"
              name="infosum"
              searchText={searchText}
              subTitle=""
              disableButton
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              type="PIANO"
              buttonLabel="Coming Soon"
              name="piano"
              searchText={searchText}
              subTitle=""
              disableButton
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              type="INDEX_EXCHANGE"
              buttonLabel="Coming Soon"
              name="index exchange"
              searchText={searchText}
              subTitle=""
              disableButton
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              type="SYMITRI"
              buttonLabel="Coming Soon"
              name="symitri"
              searchText={searchText}
              subTitle=""
              disableButton
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              name="lotame"
              searchText={searchText}
              type="LOTAME"
              buttonLabel="Coming Soon"
              subTitle=""
              disableButton
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              name="google ad manager"
              searchText={searchText}
              type="GOOGLE_AD_MANAGER"
              buttonLabel="Coming Soon"
              subTitle=""
              disableButton
              title={""}
            />
            {/* <ConnectionTile
              clickUrl="/console/csv-data-endpoint"
              imageSubTitle=""
              name="CSV"
              searchText={searchText}
              type="CSV"
              buttonLabel="Configure"
              subTitle=""
              title={""}
            /> */}
            {/* <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              type="SEGMENT"
              buttonLabel="Coming Soon"
              name="segment"
              searchText={searchText}
              subTitle=""
              disableButton
              title={""}
            />              */}
          </Row>
        </div>
      );
    }
  }

  function renderAuthenticationServices() {
    if (
      (selectedCategory === 0 || selectedCategory === 3) &&
      displayAuthServices
    ) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <div style={{ flexDirection: "column", display: "flex" }}>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontStyle: "normal",
                color: "white",
                fontWeight: "700",
              }}
            >
              Authentication Services
            </span>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontStyle: "normal",
                color: "#8B8B8B",
                fontWeight: "400",
              }}
            >
              Include Login with lockrMail seamlessly through these partner
              platforms and standards.
            </span>
          </div>
          <Row
            style={{
              display: "flex",
              marginTop: 24,
              flexWrap: "wrap",
              gap: 20,
            }}
          >
            <ConnectionTile
              clickUrl="/console/autho-authentication"
              imageSubTitle=""
              type="OKTA"
              buttonLabel="Configure"
              subTitle=""
              name="okta"
              searchText={searchText}
              title={""}
            />
            <ConnectionTile
              clickUrl="/console/google-authentication"
              imageSubTitle=""
              type="GOOGLE"
              buttonLabel="Configure"
              subTitle=""
              name="Google"
              searchText={searchText}
              title={""}
            />
            <ConnectionTile
              clickUrl="/console/piano-authentication"
              imageSubTitle=""
              type="PIANO"
              name="piano"
              searchText={searchText}
              buttonLabel="Configure"
              subTitle=""
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              name="stytch"
              searchText={searchText}
              type="STYTCH"
              buttonLabel="Coming Soon"
              subTitle=""
              disableButton
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              name="zuora"
              searchText={searchText}
              type="ZEPHR"
              buttonLabel="Coming Soon"
              subTitle=""
              disableButton
              title={""}
            />
          </Row>
        </div>
      );
    }
  }

  function renderIdentityProviders() {
    if (
      (selectedCategory === 0 || selectedCategory === 2) &&
      displayIdentityProviders
    ) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <div style={{ flexDirection: "column", display: "flex" }}>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontStyle: "normal",
                color: "white",
                fontWeight: "700",
              }}
            >
              Identity Providers
            </span>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontStyle: "normal",
                color: "#8B8B8B",
                fontWeight: "400",
              }}
            >
              Activate Alternative IDs seamlessly with AIM.
            </span>
          </div>
          <Row
            style={{
              display: "flex",
              marginTop: 24,
              flexWrap: "wrap",
              gap: 20,
            }}
          >
            <ConnectionTile
              clickUrl="/console/uid-token"
              imageSubTitle=""
              type="UID"
              buttonLabel="Configure Now"
              subTitle=""
              name="uid"
              searchText={searchText}
              isActive={activityData?.uid >= 1}
              title={""}
            />
            <ConnectionTile
              clickUrl="/console/ramp-id"
              imageSubTitle=""
              type="LIVERAMP"
              name="liveramp"
              searchText={searchText}
              buttonLabel="Configure Now"
              subTitle=""
              isActive={activityData?.rampid >= 1 || activityData?.rampjs >= 1}
              title={""}
            />
            <ConnectionTile
              clickUrl="/console/id5"
              imageSubTitle=""
              type="ID5"
              name="id5"
              searchText={searchText}
              buttonLabel="Configure Now"
              isActive={activityData?.id5 >= 1}
              subTitle=""
              title={""}
            />
            <ConnectionTile
              clickUrl="/console/euid"
              imageSubTitle=""
              type="EUID"
              name="euid"
              searchText={searchText}
              buttonLabel="Configure Now"
              isActive={activityData?.euid >= 1}
              subTitle=""
              title={""}
            />
            <ConnectionTile
              clickUrl="/console/yahoo-connect-id"
              imageSubTitle=""
              name="yahoo"
              searchText={searchText}
              type="YAHOO"
              isActive={activityData?.yahooConnectId >= 1}
              buttonLabel="Configure Now"
              subTitle=""
              title={""}
            />
            <ConnectionTile
              clickUrl=""
              imageSubTitle=""
              name="criteo"
              searchText={searchText}
              type="CRITEO"
              buttonLabel="Coming Soon"
              subTitle=""
              disableButton
              title={""}
            />
            <ConnectionTile
              clickUrl="/console/first-id"
              imageSubTitle=""
              name="firstid"
              searchText={searchText}
              type="FIRSTID"
              buttonLabel="Configure Now"
              isActive={activityData?.firstId >= 1}
              subTitle=""
              title={""}
            />
            <ConnectionTile
              clickUrl="/console/panorama-id"
              imageSubTitle=""
              name="lotame panorama id"
              searchText={searchText}
              type="LOTAME"
              buttonLabel="Configure Now"
              isActive={activityData?.panoramaId >= 1}
              subTitle=""
              title={""}
              />
          </Row>
        </div>
      );
    }
  }

  function renderDataConnectors() {
    if (
      (selectedCategory === 0 || selectedCategory === 1) &&
      displayDataConnectors
    ) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <div style={{ flexDirection: "column", display: "flex" }}>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "24px",
                fontStyle: "normal",
                color: "white",
                fontWeight: "700",
              }}
            >
              Data Connectors
            </span>
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontStyle: "normal",
                color: "#8B8B8B",
                fontWeight: "400",
              }}
            >
              Collaborate with leading contextual vendors, enrich your first
              party data with additional attributes, and validate the impact of
              Machine Generated Emails on your media activation.
            </span>
          </div>
          <Row
            style={{
              display: "flex",
              marginTop: 24,
              flexWrap: "wrap",
              gap: 20,
            }}
          >
            <ConnectionTile
              clickUrl="/console/self-serve"
              imageSubTitle=""
              title={"lockr Self-Serve"}
              subTitle={"Short description of this connection goes here..."}
              buttonLabel="Configure Now"
              name="lockr self serve"
              searchText={searchText}
              isActive
              supportEmailConnector
              type={"SELF_SERVE"}
            />
            <ConnectionTile
              clickUrl="/console/connectionList?type=Continuous"
              imageSubTitle="Continuous"
              type="SNOWFLAKE"
              name="snowflake"
              supportEmailConnector
              searchText={searchText}
              buttonLabel="Configure Now"
              isActive={activityData?.continous >= 1}
              subTitle="Deploy a continuous integration between lockr and Snowflake api"
              title={"Snowflake - Continuous"}
            />
            <ConnectionTile
              clickUrl="/console/connectionList?type=Snapshot"
              imageSubTitle="Snapshot"
              buttonLabel="Create Now"
              name="snowflake"
              supportEmailConnector
              searchText={searchText}
              isActive={activityData?.snapshot >= 1}
              type="SNOWFLAKE"
              subTitle="A one time link between lockr and Snowflake to bring over data."
              title={"Snowflake - Snapshot"}
            />
            <ConnectionTile
              clickUrl="/console/infosum"
              imageSubTitle=""
              name="infosum"
              supportEmailConnector
              searchText={searchText}
              title={"Infosum"}
              isActive={activityData?.infosum >= 1}
              subTitle={"Short description of this connection goes here..."}
              buttonLabel="Create Connection"
              type={"INFOSUM"}
            />
            <ConnectionTile
              type="AWS"
              name="aws"
              supportEmailConnector
              searchText={searchText}
              disableButton={false}
              isActive={activityData?.aws >= 1}
              clickUrl="/console/aws-connection"
              buttonLabel="Create Connection"
              subTitle="A one time link between lockr and AWS Clean rooms"
              title={"AWS - Clean Rooms"}
            />
            <ConnectionTile
              clickUrl="/console/self-serve?batch=true"
              imageSubTitle=""
              title={"lockr Self-Serve"}
              subTitle={"Short description of this connection goes here..."}
              buttonLabel="Configure Now"
              name="lockr self serve"
              searchText={searchText}
              isActive
              supportBatchConnector
              type={"SELF_SERVE"}
            />
            <ConnectionTile
              clickUrl="/console/connectionList?type=Continuous&batch=true"
              imageSubTitle="Continuous"
              type="SNOWFLAKE"
              name="snowflake"
              supportBatchConnector
              searchText={searchText}
              buttonLabel="Configure Now"
              isActive={activityData?.continousBatch}
              subTitle="Deploy a continuous integration between lockr and Snowflake api"
              title={"Snowflake - Continuous"}
            />
            <ConnectionTile
              clickUrl="/console/connectionList?type=Snapshot&batch=true"
              imageSubTitle="Snapshot"
              buttonLabel="Create Now"
              name="snowflake"
              supportBatchConnector
              searchText={searchText}
              isActive={activityData?.snapshotBatch}
              type="SNOWFLAKE"
              subTitle="A one time link between lockr and Snowflake to bring over data."
              title={"Snowflake - Snapshot"}
            />
            <ConnectionTile
              clickUrl="/console/infosum?batch=true"
              imageSubTitle=""
              name="infosum"
              supportBatchConnector
              searchText={searchText}
              title={"Infosum"}
              isActive={activityData?.infosumBatch}
              subTitle={"Short description of this connection goes here..."}
              buttonLabel="Create Connection"
              type={"INFOSUM"}
            />
             <ConnectionTile
              type="AWS"
              name="aws"
              supportBatchConnector
              searchText={searchText}
              disableButton={false}
              isActive={activityData?.awsBatch}
              clickUrl="/console/aws-connection?batch=true"
              buttonLabel="Create Connection"
              subTitle="A one time link between lockr and AWS Clean rooms"
              title={"AWS - Clean Rooms"}
            />
            <ConnectionTile
              type="BOMBORA"
              name="bombora"
              searchText={searchText}
              disableButton={true}
              isActive={false}
              clickUrl=""
              buttonLabel="Coming Soon"
              subTitle="A one time link between lockr and AWS Clean rooms"
            />
            <ConnectionTile
              type="ARCSPAN"
              name="arcspan"
              imageSubTitle="ROI Alt IDs"
              searchText={searchText}
              disableButton={true}
              isActive={false}
              clickUrl=""
              buttonLabel="Coming Soon"
              subTitle="A one time link between lockr and AWS Clean rooms"
            />
            <ConnectionTile
              type="ARCSPAN"
              name="arcspan"
              imageSubTitle="Client"
              searchText={searchText}
              disableButton={true}
              isActive={false}
              clickUrl=""
              buttonLabel="Coming Soon"
              subTitle="A one time link between lockr and AWS Clean rooms"
            />
            <ConnectionTile
              type="BLUECONIC"
              name="blueconic"
              searchText={searchText}
              disableButton={true}
              isActive={false}
              clickUrl=""
              buttonLabel="Coming Soon"
              subTitle="A one time link between lockr and AWS Clean rooms"
            />
            <ConnectionTile
              type="ILLUMA"
              name="illuma"
              clickUrl="/console/illuma"
              searchText={searchText}
              // disableButton={true}
              isActive={activityData?.illuma >= 1}
              buttonLabel="Configure Now"
              subTitle="A one time link between lockr and AWS Clean rooms"
            />
            <ConnectionTile
              type="EXPERIAN"
              name="experian"
              searchText={searchText}
              disableButton={true}
              isActive={false}
              clickUrl=""
              buttonLabel="Coming Soon"
              subTitle="A one time link between lockr and AWS Clean rooms"
            />
            <ConnectionTile
              type="TRANS_UNION"
              name="trans union"
              searchText={searchText}
              disableButton={true}
              isActive={false}
              clickUrl=""
              buttonLabel="Coming Soon"
              subTitle="A one time link between lockr and AWS Clean rooms"
            />
            <ConnectionTile
              type="AQFER"
              name="aqfer"
              searchText={searchText}
              disableButton={true}
              isActive={false}
              clickUrl=""
              buttonLabel="Coming Soon"
              subTitle="A one time link between lockr and AWS Clean rooms"
            />
            {/* <ConnectionTile
              imageSubTitle=""
              type="SEGMENT"
              title={""}
              name="segment"
              searchText={searchText}
              disableButton={true}
              buttonLabel="Coming Soon"
              subTitle="Short description of this connection goes here..."
            /> */}
          </Row>
        </div>
      );
    }
  }

  function renderConnectionHub() {
    return (
      <>
        <Col xs={12} sm={5} lg={5}>
          <span
            style={{
              fontFamily: "Montserrat",
              fontSize: 14,
              fontWeight: "700",
              color: "white",
            }}
          >
            Types
          </span>
          <div style={{ marginTop: 24 }} />
          <ConnectionTileTypeSelector
            onPress={() => {
              setSelectedCategory(0);
            }}
            title="Show All"
            isSelected={selectedCategory === 0}
          />
          {displayIdentityProviders && (
            <ConnectionTileTypeSelector
              title="Identity Providers"
              isSelected={selectedCategory === 2}
              onPress={() => {
                setSelectedCategory(2);
              }}
            />
          )}
          {displayDataEndPoints && (
            <ConnectionTileTypeSelector
              title="Data Endpoints"
              isSelected={selectedCategory === 4}
              onPress={() => {
                setSelectedCategory(4);
              }}
            />
          )}
          {displayDataConnectors && (
            <ConnectionTileTypeSelector
              title="Data Connectors"
              onPress={() => {
                setSelectedCategory(1);
              }}
              isSelected={selectedCategory === 1}
            />
          )}
          {displayAuthServices && (
            <ConnectionTileTypeSelector
              title="Authentication Services"
              isSelected={selectedCategory === 3}
              onPress={() => {
                setSelectedCategory(3);
              }}
            />
          )}
          <div style={{ marginTop: 32 }} />
          <AddMoreConnectionTile />
        </Col>
        <Col xs={12} sm={19} lg={19} style={{ paddingLeft: 32 }}>
          <div style={{ width: "100%", position: "relative" }}>
            <Input
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder="Search connections..."
              className="connection-hub-search"
            />
            <div style={{ position: "absolute", left: 16, top: 16 }}>
              <SearchIcon />
            </div>
          </div>
          <div style={{ marginTop: 40 }} />
          {renderIdentityProviders()}
          {selectedCategory === 0 && displayIdentityProviders && (
            <div style={{ marginTop: 100 }} />
          )}
          {renderDataEndpoints()}
          {selectedCategory === 0 && displayDataEndPoints && (
            <div style={{ marginTop: 100 }} />
          )}
          {renderDataConnectors()}
          {selectedCategory === 0 && displayDataConnectors && (
            <div style={{ marginTop: 100 }} />
          )}
          {renderAuthenticationServices()}
        </Col>
      </>
    );
  }

  return (
    <SideDrawer>
      <Row
        justify="space-between"
        align="middle"
        className="apiandservices-newapp-btn"
      >
        <div>
          <Typography.Title className="apiandservices-heading">
            Identity lockr Connections
          </Typography.Title>
          <div
            style={{ marginTop: 16, display: "flex", flexDirection: "column" }}
          >
            <label
              className="dashboard-as-of-today"
              style={{
                fontSize: 14,
                fontFamily: "Montserrat",
                color: "#8B8B8B",
              }}
            >
              lockr works where you do. Our self-service platform unlocks your first party data through identity partnerships, native authentication support and data pipes.
            </label>
            {/* <label
              className="dashboard-as-of-today"
              style={{
                fontSize: 14,
                fontFamily: "Montserrat",
                color: "#8B8B8B",
              }}
            >
              while still protecting users’ privacy and incorporating consumer
              consent.
            </label> */}
          </div>
        </div>
      </Row>
      <div
        style={{
          width: "100%",
          marginTop: 40,
          height: 0.5,
          backgroundColor: "#353738",
        }}
      ></div>
      <Row style={{ paddingLeft: 90, marginTop: 40, paddingRight: 90 }}>
        {renderConnectionHub()}
      </Row>
    </SideDrawer>
  );
}

export default Connections;
