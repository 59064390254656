import React, { useState } from 'react'
import { Button, Dropdown, Modal, Row, Typography } from "antd";
import { TitleText } from './NewAppModal';
import { mapUserRoleToString, USER_ROLE_ENUM } from '../Utils/Helper';
import { AiOutlineDown } from 'react-icons/ai';

function UserRoleDropDown({ value, onChange }) {

    const menu = (
        <div className='verticle-dropdown-container' style={{ height: 108, background: 'black', overflowY: 'scroll' }}>
            <div style={{ cursor: 'pointer', color: '#fff' }} className='frequency-dropdown-option' onClick={() => {
                onChange(USER_ROLE_ENUM.DEVELOPER);
            }}>
                <div style={{ display: 'inline-block', width: 32, height: 12 }}>
                </div>
                {mapUserRoleToString(USER_ROLE_ENUM.DEVELOPER)}
            </div>
            <div style={{ cursor: 'pointer', color: '#fff' }} className='frequency-dropdown-option' onClick={() => {
                onChange(USER_ROLE_ENUM.MARKETING_ASSISTANCE);
            }}>
                <div style={{ display: 'inline-block', width: 32, height: 12 }}>
                </div>
                {mapUserRoleToString(USER_ROLE_ENUM.MARKETING_ASSISTANCE)}
            </div>
            <div style={{ cursor: 'pointer', color: '#fff' }} className='frequency-dropdown-option' onClick={() => {
                onChange(USER_ROLE_ENUM.ADMIN);
            }}>
                <div style={{ display: 'inline-block', width: 32, height: 12 }}>
                </div>
                {mapUserRoleToString(USER_ROLE_ENUM.ADMIN)}
            </div>
        </div>
    )

    return (
        <div className='snowflake-frequency-picker-container'>
            <Dropdown overlay={menu} trigger={['click']} className='vertical-dropdown'>
                <div className='lockr-signup-control' style={{ position: 'relative', height: 48 }}>
                    <AiOutlineDown style={{ position: 'absolute', right: 16, top: 12, color: 'white' }} />
                    <TitleText
                        onFocusCapture={() => { }}
                        onBlurCapture={() => { }}
                        isPointer
                        style={{ color: '#fff', borderColor: '#8B8B8B', borderRadius: 2, borderTopWidth: 0, cursor: 'pointer' }}
                        value={mapUserRoleToString(value)}
                        placeholder={' '}
                        isReadOnly
                    />
                </div>

            </Dropdown>
        </div>
    )
}

export function CreateUserModal({ display, onSave, onCancel, defaultUserDetails, errorText, removeError }) {

    const [userDetails, setUserDetails] = useState({
        firstName: defaultUserDetails?.firstName ?? '', lastName: defaultUserDetails?.lastName ?? '', email: defaultUserDetails?.email ?? '', password: '', role: defaultUserDetails?.role ?? ''
    });

    function checkValidation() {
        let validation = true;
        const _emailReg = new RegExp(/^[^@]+@[^@]{2,}\.[^@]{2,}$/)
        const { firstName, lastName, email, role } = userDetails;
        if (firstName.trim() === '' || lastName.trim() === '') {
            validation = false;
        }
        if (email.trim() === '' || !_emailReg.test(email)) {
            validation = false;
        }
        if (role.trim() === '') {
            validation = false;
        }
        return validation;
    }

    return (
        <Modal
            visible={display}
            style={{ top: 20, paddingBottom: 20 }}
            centered
            width={776}
            footer={null}
            closable={false}
            destroyOnClose
            bodyStyle={{ minHeight: 500, width: 776, backgroundColor: '#353738', paddingLeft: 60, paddingTop: 40, paddingRight: 0, overflowX: 'hidden' }}
        >
            <img alt={'Close'} onClick={onCancel} src={require('../assets/modal-close.png')} className="delete-x-button-connection" />
            <div>
                <Typography.Title style={{ display: 'inline-block', fontFamily: 'Montserrat', color: '#fff', fontWeight: 700, fontSize: 20, paddingBottom: 40, paddingTop: 8 }}>{`${defaultUserDetails ? 'Edit' : 'New'} User`}</Typography.Title>
            </div>
            <div>
                <TitleText
                    value={userDetails.firstName}
                    placeholder={' '}
                    marginLeft={148}
                    label={'First Name'}
                    onChange={({ target: { value } }) => setUserDetails({
                        ...userDetails, firstName: value
                    })}
                />
            </div>
            <div style={{ marginTop: 16 }} />
            <div>
                <TitleText
                    value={userDetails.lastName}
                    placeholder={' '}
                    marginLeft={148}
                    label={'Last Name'}
                    onChange={({ target: { value } }) => setUserDetails({
                        ...userDetails, lastName: value
                    })}
                />
            </div>
            <div style={{ marginTop: 16 }} />
            <div>
                <TitleText
                    value={userDetails.email}
                    placeholder={' '}
                    marginLeft={errorText ? 163 : 188}
                    label={'Email'}
                    placement = 'right'
                    errorInfo = {!!errorText}
                    errorText = {errorText}
                    disabled={defaultUserDetails !== undefined && defaultUserDetails !== null}
                    onChange={({ target: { value } }) => {
                        removeError();
                        setUserDetails({
                            ...userDetails, email: value
                        })
                    }}
                />
                {/* {errorText !== undefined && <div style={{marginLeft: 236, width: 390}}>
                    <label className='errorText'>{errorText}</label>
                </div>
                } */}

            </div>
            <div style={{ marginTop: 16 }} />
            <div>
                <TitleText
                    placeholder={' '}
                    marginLeft={196}
                    label={'Role'}
                    customInput={
                        <UserRoleDropDown
                            value={userDetails.role}
                            onChange={role => { setUserDetails({ ...userDetails, role }) }}
                        />
                    }
                    onChange={({ target: { value } }) => setUserDetails({
                        ...userDetails, role: value
                    })}
                />
            </div>
            <Row style={{ marginTop: 50 }}>
                <Button
                    type={'primary'}
                    style={{ width: 200 }}
                    loading={false}
                    disabled={!checkValidation()}
                    onClick={() => {
                        onSave(userDetails);
                    }}
                    className={'sign-in'}
                >{"Save"}</Button>
            </Row>
        </Modal>
    )
}