/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Checkbox, Modal, Tooltip, Typography } from "antd";
import { TitleText } from "./NewAppModal";
import { TitleTextPassword } from "./TitleTextPassword";
import { identitProvidersType } from "../Utils/Helper";

export function LockrInfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <circle
        cx="7"
        cy="7.5"
        r="6.5"
        transform="rotate(-180 7 7.5)"
        stroke="white"
        stroke-linecap="round"
      />
      <line
        x1="6.79175"
        y1="10.6895"
        x2="6.79175"
        y2="6.68945"
        stroke="white"
        stroke-linecap="round"
      />
      <line
        x1="6.79175"
        y1="4.08594"
        x2="6.79175"
        y2="4.58594"
        stroke="white"
        stroke-linecap="round"
      />
    </svg>
  );
}

export function UIDCredentialsModal({
  display,
  onOk,
  onCancel,
  credentials,
  error,
  onHideError = () => {},
  loading,
  integrationType = identitProvidersType.UID,
  disableLockrKeys = false,
}) {
  const [modalCredentials, setModalCredentials] = useState(
    credentials ?? {
      accessKey: "",
      secretKey: "",
      useLockrKeys: false,
    }
  );

  useEffect(() => {
    onHideError();
  }, [modalCredentials]);

  function renderTNCLine() {
    return (
      <div
        style={{
          marginTop: 24,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: "#EDEDED",
            fontFamily: "Montserrat",
            fontSize: 14,
            fontWeight: "400",
          }}
        >
          Don’t yet have a{" "}
          {integrationType === identitProvidersType.UID
            ? "UID2"
            : integrationType === identitProvidersType.EUID
            ? "EUID"
            : "LiveRamp ATS"}{" "}
          account?
        </span>
        <span
          onClick={() => {
            if (integrationType === identitProvidersType.UID) {
              window.open("https://unifiedid.com/request-access", "_blank");
            } else if (integrationType === identitProvidersType.EUID) {
              window.open("https://euid.eu/request-access", "_blank");
            } else if (integrationType === identitProvidersType.RAMPJS) {
              window.open(
                "https://docs.liveramp.com/identity/en/implementing-liveramp-s-real-time-identity-service-tag.html",
                "_blank"
              );
            } else {
              window.open("https://launch.liveramp.com/auth/sign-up", "_blank");
            }
          }}
          style={{
            marginLeft: 8,
            color: "white",
            textDecoration: "underline",
            cursor: "pointer",
            fontWeight: "600",
            fontSize: 14,
          }}
        >
          Create one here
        </span>
      </div>
    );
  }

  function renderLiveRampSpecificSettings() {
      if(integrationType === identitProvidersType.RAMPID) {
        return (
          <div style = {{
              display: 'flex',
              flexDirection: 'row',
              gap: 32
          }}>
              <div>
                  <Checkbox
                    checked={modalCredentials.isRampATS || !modalCredentials.isRampRTIS}
                    onChange={(event) => {
                      setModalCredentials({
                        ...modalCredentials,
                        isRampATS: event.target.checked,
                        isRampRTIS: !event.target.checked
                      });
                    }}
                  />
                  <span style = {{
                      marginLeft: 16,
                      color: "white",
                      fontSize: 16,
                      fontFamily: "Montserrat",
                  }}>ATS</span>
              </div>
              <div>
                  <Checkbox
                    checked={modalCredentials.isRampRTIS}
                    onChange={(event) => {
                      setModalCredentials({
                        ...modalCredentials,
                        isRampRTIS: event.target.checked,
                        isRampATS: !event.target.checked
                      });
                    }}
                  />
                  <span style = {{
                      marginLeft: 16,
                      color: "white",
                      fontSize: 16,
                      fontFamily: "Montserrat",
                  }}>RTIS</span>
              </div>
          </div>
        );
      }
      return <></>;
  }

  return (
    <Modal
      style={{ top: 20, paddingBottom: 20 }}
      centered
      width={750}
      footer={null}
      closable={false}
      destroyOnClose
      bodyStyle={{
        minHeight:
          integrationType === identitProvidersType.UID ||
          integrationType === identitProvidersType.EUID
            ? 480
            : 400,
        width: 750,
        backgroundColor: "#353738",
        paddingRight: 60,
        paddingLeft: 60,
        paddingTop: 60,
        overflowX: "hidden",
      }}
      visible={display}
    >
      <div style={{ padding: 0 }}>
        <img
          style={{ right: 60 }}
          alt={"Close"}
          onClick={onCancel}
          src={require("../assets/modal-close.png")}
          className="delete-x-button-connection"
        />
        <div>
          <Typography.Title
            style={{
              display: "inline-block",
              fontFamily: "Montserrat",
              color: "#fff",
              fontWeight: 700,
              fontSize: 20,
              paddingBottom: 40,
              paddingTop: 8,
            }}
          >
            {"Credentials"}
          </Typography.Title>
        </div>
        <div style={{ marginTop: 16 }} />
        {(integrationType === identitProvidersType.UID ||
          integrationType === identitProvidersType.EUID) && (
          <TitleTextPassword
            placeholder={" "}
            label={
              integrationType === identitProvidersType.UID ||
              integrationType === identitProvidersType.EUID
                ? "Access Key"
                : "Placement ID"
            }
            supportDisabledColor
            eyeRight={62}
            hideEye={modalCredentials.useLockrKeys}
            marginLeft={
              integrationType === identitProvidersType.UID ||
              integrationType === identitProvidersType.EUID
                ? 162
                : 120
            }
            supportCopy
            value={
              modalCredentials.useLockrKeys
                ? "***********"
                : modalCredentials?.accessKey
            }
            disabled={modalCredentials.useLockrKeys}
            onChange={(event) =>
              setModalCredentials({
                ...modalCredentials,
                accessKey: event.target.value,
              })
            }
          />
        )}
        {(integrationType === identitProvidersType.RAMPID ||
          integrationType === identitProvidersType.RAMPJS) && (
          <TitleTextPassword
            placeholder={" "}
            label={
              integrationType === identitProvidersType.UID
                ? "Access Key"
                : "Placement ID"
            }
            hideEye={modalCredentials.useLockrKeys}
            supportDisabledColor
            eyeRight={80}
            marginLeft={
              integrationType === identitProvidersType.UID ? 162 : 120
            }
            supportCopy
            value={
              modalCredentials.useLockrKeys
                ? "***********"
                : modalCredentials?.accessKey
            }
            disabled={modalCredentials.useLockrKeys}
            onChange={(event) =>
              setModalCredentials({
                ...modalCredentials,
                accessKey: event.target.value,
              })
            }
          />
        )}
        <div style={{ marginTop: 24 }} />
        {integrationType !== identitProvidersType.RAMPID &&
          integrationType !== identitProvidersType.RAMPJS && (
            <TitleTextPassword
              disabled={modalCredentials.useLockrKeys}
              placeholder={" "}
              supportDisabledColor
              eyeRight={62}
              hideEye={modalCredentials.useLockrKeys}
              label={
                integrationType === identitProvidersType.UID ||
                integrationType === identitProvidersType.EUID
                  ? "Secret Key"
                  : "Client Secret"
              }
              marginLeft={
                integrationType === identitProvidersType.UID ||
                integrationType === identitProvidersType.EUID
                  ? 166
                  : 128
              }
              value={
                modalCredentials.useLockrKeys
                  ? "***********"
                  : modalCredentials?.secretKey
              }
              supportCopy
              onChange={(event) =>
                setModalCredentials({
                  ...modalCredentials,
                  secretKey: event.target.value,
                })
              }
            />
          )}
        {/* {integrationType !== identitProvidersType.EUID && */}
        {renderLiveRampSpecificSettings()}
        {integrationType !== identitProvidersType.RAMPJS && (
          <div
            style={{
              marginTop: 24,
              display: "flex",
              alignItems: "center",
              cursor: disableLockrKeys ? "not-allowed" : "pointer",
              // pointerEvents: disableLockrKeys ? "none" : "auto",
            }}
          >
            <Checkbox
              className="checkbox-lockr-keys"
              disabled={disableLockrKeys}
              checked={disableLockrKeys ? false : modalCredentials.useLockrKeys}
              onChange={(event) => {
                setModalCredentials({
                  ...modalCredentials,
                  useLockrKeys: event.target.checked,
                });
              }}
            />
            <span
              style={{
                marginLeft: 16,
                color: disableLockrKeys ? "gray" : "white",
                fontSize: 16,
                fontFamily: "Montserrat",
              }}
            >
              Use lockrKeys
            </span>
            <Tooltip
              style={{
                cursor: "pointer",
              }}
              title="Publishers can use their own credentials with service providers or choose to rely on lockr's pre-established connection."
            >
              <div
                style={{
                  marginLeft: 16,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LockrInfoIcon />
              </div>
            </Tooltip>
          </div>
        )}
        {disableLockrKeys && (
          <span
            style={{
              marginLeft: 32,
              color: "gray",
              fontSize: 14,
              fontFamily: "Montserrat",
              cursor: disableLockrKeys ? "not-allowed" : "pointer",
            }}
          >
            Contact your lockr account representative to unlock lockrKeys for
            EUID
          </span>
        )}
        {/* } */}
        <div style={{ marginTop: 24 }} />
        <Button
          style={{ width: 217, marginTop: 24, cursor: "pointer" }}
          type="primary"
          className="sign-in"
          onClick={() => onOk(modalCredentials)}
        >
          Confirm
        </Button>
        <div>
          <span className="errorText">{error}</span>
        </div>
        {renderTNCLine()}
      </div>
    </Modal>
  );
}
