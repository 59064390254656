import { DatePicker, Dropdown } from "antd"
import { AiOutlineCheck, AiOutlineDown, AiOutlinePlus } from "react-icons/ai"
import deleteIcon from '../../../../assets/remove_icon.png'
import arrowWhiteDown from '../../../../assets/arrow-white-down.svg'
import { attributesNotToIncudeInDropdown, conditionDropdown, dateConditionDropdown, uuidConditionDropdown } from "../CSVDataEndpoint"
import { useEffect, useState } from "react"
import filterGroup from '../../../../assets/filter-group.svg'
import plusCircle from '../../../../assets/plus-circle.svg'
import moment from "moment"

const SingleFilter = ({ filter, index, editEnabled = false, attributes, updateSelectedCohortFilters = () => { }, selectedCohort, displayCondition = true, onRemoveFilter = () => { } }) => {
    const dateFormat = "dddd MM/DD";
    const customFormat = (value) => `${value.format(dateFormat)}`;
    return <div style={{ display: 'flex', gap: 8, alignItems: 'center', marginBottom: 8 }}>

        {/* DROPDOWN FOR OR/ AND */}
        {displayCondition && <Dropdown trigger={["click"]} overlay={
            <div style={{ background: 'black', padding: 8, color: 'white' }}>
                <div onClick={() => {
                    let tempFilter = JSON.parse(JSON.stringify(filter))
                    tempFilter.config.condition = "AND"
                    updateSelectedCohortFilters(tempFilter)
                }} style={{ padding: 4, cursor: 'pointer' }}>AND</div>
                <div style={{ padding: 4, cursor: 'pointer' }} onClick={() => {
                    let tempFilter = JSON.parse(JSON.stringify(filter))
                    tempFilter.config.condition = "OR"
                    updateSelectedCohortFilters(tempFilter)
                }}>OR</div>
            </div>}>

            <div style={{ height: 40, width: 80, background: '#26282A', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 8, paddingInline: 16, borderRadius: 2, border: '1px solid #353738' }}>
                {filter?.config?.condition}
                {editEnabled && <img src={arrowWhiteDown} />}
            </div>
        </Dropdown>}

        {/* DROPDOWN FOR ATTRIBUTES */}
        <Dropdown disabled={!(editEnabled)} trigger={["click"]} overlay={<div style={{ background: 'black', padding: 8, width: 220 }}>
            {attributes.filter(a => !attributesNotToIncudeInDropdown.includes(a.key))?.map(cd => (
                <div onClick={() => {
                    let tempFilter = JSON.parse(JSON.stringify(filter))
                    tempFilter.config.operand = cd.key
                    tempFilter.config.value = ""
                    tempFilter.config.type = cd.type
                    tempFilter.config.operator = ""
                    updateSelectedCohortFilters(tempFilter)

                }} style={{ color: 'white', padding: 4 }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.attr ? "#61CEF7" : "transparent"} />{cd.label}</div>
                </div>
            ))}
        </div>}>
            <div style={{ position: 'relative' }}>
                <input style={{ minHeight: 40, width: 220, minWidth: 220, cursor: 'pointer', color: 'white', background: '#171717', border: ' 1px solid #353738', borderRadius: '2px', marginTop: 0 }} placeholder="Select Attribute" readOnly className="lockr-data-endpoint-input" value={attributes?.find(a => a.key === filter?.config?.operand)?.label}></input>
                <div style={{ position: 'absolute', top: 8, right: 10 }}>
                    {editEnabled && <img src={arrowWhiteDown} />}
                </div>
            </div>

        </Dropdown>

        {/* DROPDOWN FOR CONDITIONS */}
        <Dropdown disabled={!(editEnabled)} trigger={["click"]} overlay={
            <div style={{ background: 'black', padding: 8, width: 170 }}>
                {attributes.find(a => a.key === filter.config.type)?.type === "source" ?
                    attributes.find(a => a.key === filter?.config?.type).acceptedValues.map(cd => (
                        <div onClick={() => {

                            if (cd.acceptedValues) {
                                let tempFilter = JSON.parse(JSON.stringify(filter))
                                tempFilter.config.operand = cd.key
                                tempFilter.config.value = ""
                                tempFilter.config.type = "source"
                                tempFilter.config.operator = "is"
                                updateSelectedCohortFilters(tempFilter)
                            }
                            else {
                                let tempFilter = JSON.parse(JSON.stringify(filter))
                                tempFilter.config.operand = cd.key
                                tempFilter.config.value = ""
                                tempFilter.config.type = "source"
                                tempFilter.config.operator = "is"
                                updateSelectedCohortFilters(tempFilter)

                            }

                        }} style={{ color: 'white', padding: 4 }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter?.config?.operand ? "#61CEF7" : "transparent"} />{cd.label}</div>
                        </div>
                    )) : attributes.find(a => a.key === filter.config.operand)?.type === "date" ? dateConditionDropdown.map(cd => (
                        <div onClick={() => {

                            let tempFilter = JSON.parse(JSON.stringify(filter));
                            tempFilter.config.operator = cd.key
                            tempFilter.config.value = ""
                            tempFilter.config.type = "date"
                            updateSelectedCohortFilters(tempFilter)
                        }} style={{ color: 'white', padding: 4 }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.config.operator ? "#61CEF7" : "transparent"} />{cd.label}</div>
                        </div>
                    )) :
                        conditionDropdown.map(cd => (
                            <div onClick={() => {
                                let tempFilter = JSON.parse(JSON.stringify(filter));
                                tempFilter.config.operator = cd.key
                                tempFilter.config.value = ""
                                tempFilter.config.type = "string"
                                updateSelectedCohortFilters(tempFilter)
                            }} style={{ color: 'white', padding: 4 }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={cd.key === filter.config.operator ? "#61CEF7" : "transparent"} />{cd.label}</div>
                            </div>
                        ))}
            </div>
        }>
            <div style={{ position: 'relative' }}>
                <input style={{ minHeight: 40, width: 220, minWidth: 220, cursor: 'pointer', color: 'white', background: '#171717', border: ' 1px solid #353738', borderRadius: '2px', marginTop: 0 }} placeholder="Select Condition" readOnly className="lockr-data-endpoint-input"
                    value={filter?.config?.type === "source" ? attributes.find(a => a.key === filter.config.type).acceptedValues.find(ac => ac.key === filter.config.operand)?.label : filter?.config?.type == "date" ? dateConditionDropdown.find(cd => cd.key === filter.config.operator)?.label ?? '' : conditionDropdown.find(cd => cd.key === filter.config.operator)?.label ?? ''}></input>
                <div style={{ position: 'absolute', top: 8, right: 10 }}>
                    {editEnabled && <img src={arrowWhiteDown} />}
                </div>
            </div>

        </Dropdown>



        {/* Value picker */}
        {filter.config.operator && (filter.config.type === 'source' ?
            <Dropdown disabled={!(editEnabled)} trigger={["click"]} overlay={
                <div style={{ background: 'black', padding: 8 }}>

                    {attributes.find(a => a.key === filter.config.type).acceptedValues.find(ac => ac.key === filter.config.operand)?.acceptedValues.map(ac => <div onClick={() => {
                        let tempFilter = JSON.parse(JSON.stringify(filter));
                        tempFilter.config.value = ac.value
                        updateSelectedCohortFilters(tempFilter)

                    }} style={{ color: 'white', padding: 4 }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={filter.config.value === ac.value ? "#61CEF7" : "transparent"} />{ac.label}</div>
                    </div>)}
                </div>}>
                <div style={{ position: 'relative', flex: 1, height: 40, marginTop: 0, background: '#26282a' }}>
                    <input value={filter.config.type === 'source'
                        ? attributes.find(a => a.key === filter.config.type)
                            ?.acceptedValues.find(av => av.key === filter.config.operand)
                            ?.acceptedValues.find(v => v.value === filter.config.value)?.label || filter.config.value
                        : filter.config.value}
                        style={{ paddingLeft: 8, backgroundColor: '#171717', cursor: 'pointer', color: 'white', minHeight: 40, marginTop: 0, background: '#171717', border: '1px solid #353738', width: '100%' }} placeholder="Select Value" readOnly className=""></input>
                    <div style={{ position: 'absolute', top: 8, right: 10 }}>
                        {editEnabled && <img src={arrowWhiteDown} />}
                    </div>
                </div>

            </Dropdown>
            : filter.config.operator === 'isNull' ?
                <Dropdown disabled={!(editEnabled)} trigger={["click"]} overlay={
                    <div style={{ background: 'black', padding: 8, width: 170 }}>
                        <div onClick={() => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));
                            tempFilter.config.value = true
                            updateSelectedCohortFilters(tempFilter)

                        }} style={{ color: 'white', padding: 4 }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={filter.config.value ? "#61CEF7" : "transparent"} />{"True"}</div>
                        </div>
                        <div onClick={() => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));
                            tempFilter.config.value = false
                            updateSelectedCohortFilters(tempFilter)

                        }} style={{ color: 'white', padding: 4 }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}> <AiOutlineCheck color={(filter.config.value === false) ? "#61CEF7" : "transparent"} />{"False"}</div>
                        </div>
                    </div>}>
                    <div style={{ position: 'relative', flex: 1, height: 40, marginTop: 0, background: '#26282a' }}>
                        <input value={filter.config.value === "" ? "" : ((filter.config.value === false) ? "False" : "True")}
                            style={{ backgroundColor: '#171717', cursor: 'pointer', color: 'white', minHeight: 40, marginTop: 0, background: '#171717', border: '1px solid #353738', width: '100%' }} placeholder="Select Value" readOnly className=""></input>
                        <div style={{ position: 'absolute', top: 8, right: 10 }}>
                            {editEnabled && <img src={arrowWhiteDown} />}
                        </div>
                    </div>

                </Dropdown> : filter.config.operator === 'between' ?
                    <>
                        <DatePicker suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>} dropdownClassName="calender-date-picker" className="dashboard-date-picker" format={customFormat} disabled={!(editEnabled)} value={filter.config.value[0] ? moment(filter.config.value[0]) : ""} onChange={(e) => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));
                            if (typeof tempFilter.config.value === 'string')
                                tempFilter.config.value = []
                            tempFilter.config.value[0] = moment(e).format("yyyy-MM-DD")
                            updateSelectedCohortFilters(tempFilter)
                        }} placeholder={conditionDropdown.find(c => c.key === filter.config.operator)?.label}
                            style={{ background: '#171717', border: '1px solid #353738', borderRadius: 2, padding: 8, height: 40, color: 'white', flex: 1, marginTop: 0 }} />
                        <DatePicker suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>} dropdownClassName="calender-date-picker" className="dashboard-date-picker" format={customFormat} disabled={!(editEnabled)} value={moment(filter.config.value[1])} onChange={(e) => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));
                            if (typeof tempFilter.config.value === 'string')
                                tempFilter.config.value = []
                            tempFilter.config.value[1] = moment(e).format("yyyy-MM-DD")
                            updateSelectedCohortFilters(tempFilter)
                        }} placeholder={conditionDropdown.find(c => c.key === filter.config.operator)?.label}
                            style={{ background: '#171717', border: '1px solid #353738', borderRadius: 2, padding: 8, height: 40, color: 'white', flex: 1, marginTop: 0 }} />
                    </> : filter.config.type === 'date' ?

                        <DatePicker suffixIcon={<AiOutlineDown color="#fff"></AiOutlineDown>} dropdownClassName="calender-date-picker" className="dashboard-date-picker" format={customFormat} disabled={!(editEnabled)} value={filter.config.value ? moment(filter.config.value) : ""} onChange={(e) => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));

                            tempFilter.config.value = moment(e).format("yyyy-MM-DD")
                            updateSelectedCohortFilters(tempFilter)
                        }} placeholder={conditionDropdown.find(c => c.key === filter.config.operator)?.label}
                            style={{
                                background: '#171717', border: '1px solid #353738', borderRadius: 2, padding: 8, height: 40, color: 'white', flex: 1, marginTop: 0
                            }} />




                        : <input disabled={!(editEnabled)} value={filter.config.value} onChange={(e) => {
                            let tempFilter = JSON.parse(JSON.stringify(filter));
                            tempFilter.config.value = e.target.value
                            updateSelectedCohortFilters(tempFilter)
                        }} placeholder={conditionDropdown.find(c => c.key === filter.config.operator)?.label}
                            style={{ background: '#171717', border: '1px solid #353738', borderRadius: 2, padding: 8, height: 40, color: 'white', flex: 1, marginTop: 0 }} />
        )}


        <div>
            {(editEnabled) && < img src={deleteIcon} alt="delete" onClick={() => {
                if (editEnabled) {
                    onRemoveFilter()

                }
            }} cursor={'pointer'} style={{ height: 24, width: 21.6 }} />}
        </div>
    </div>
}

export function CustomConditionsBuilder({ selectedCohort = { configuration: { appliedFilters: { allCondition: 'AND', filters: [] }, selectedAttributes: [], name: "", } }, editEnabled = false, attributes = [], updateSelectedCohortFilters = () => { }, updateSelectedCohortConditions = () => { } }) {
    const { configuration: filter } = selectedCohort;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return <>
        <div style={{ borderRadius: 12, marginTop: 8, background: '#171717', padding: 24, color: 'white', width: '100%' }} >
            <div style={{ marginBottom: 20, fontWeight: 700, fontSize: 16 }}>
                Filter Segment
            </div>
            <div className="cohort-builder">
                {filter?.appliedFilters?.map((f, index) => (
                    f.type === "filter" ? <SingleFilter onRemoveFilter={() => {
                        let tempFilter = JSON.parse(JSON.stringify(filter))
                        tempFilter.appliedFilters.splice(index, 1)
                        updateSelectedCohortFilters(tempFilter)
                    }} displayCondition={index > 0} attributes={attributes} filter={f} index={index} selectedCohort={selectedCohort} editEnabled updateSelectedCohortFilters={(singleFilter) => {
                        let tempFilter = JSON.parse(JSON.stringify(filter))

                        tempFilter.appliedFilters[index] = singleFilter
                        updateSelectedCohortFilters(tempFilter)
                    }} />
                        : f.type === "filterGroup" ? <div style={{ background: '#1D1D1D', padding: 20, borderRadius: 8, marginTop: 20 }}>
                            {f?.config?.map((c, i) =>
                                <SingleFilter onRemoveFilter={() => {
                                    let tempFilter = JSON.parse(JSON.stringify(filter))
                                    tempFilter.appliedFilters[index].config.splice(i, 1)
                                    if (tempFilter.appliedFilters[index].config.length === 0) {
                                        tempFilter.appliedFilters.splice(index, 1)
                                    }
                                    updateSelectedCohortFilters(tempFilter)
                                }} attributes={attributes} filter={{ config: c }} index={index} selectedCohort={selectedCohort} editEnabled updateSelectedCohortFilters={(singleFilter) => {

                                    let tempFilter = JSON.parse(JSON.stringify(filter))
                                    tempFilter.appliedFilters[index].config[i] = singleFilter.config
                                    updateSelectedCohortFilters(tempFilter)
                                }} />)}
                            <Dropdown trigger={["click"]} overlay={<div style={{ background: 'black', padding: 8, width: 174, borderRadius: 16, top: 20 }}>
                                <div style={{ fontWeight: 700, color: 'white', padding: '8px 12px' }} onClick={() => {
                                    let tempFilter = JSON.parse(JSON.stringify(filter))

                                    tempFilter.appliedFilters[index].config.push({

                                        condition: 'AND',
                                        operand: '',
                                        operator: '',
                                        type: '',
                                        value: '',


                                    })
                                    updateSelectedCohortFilters(tempFilter)

                                }}><img src={plusCircle} style={{ marginRight: 8 }} />And Filter</div>
                                <div onClick={() => {
                                    let tempFilter = JSON.parse(JSON.stringify(filter))
                                    tempFilter.appliedFilters[index].config.push({

                                        condition: 'OR',
                                        operand: '',
                                        operator: '',
                                        type: '',
                                        value: '',


                                    })
                                    updateSelectedCohortFilters(tempFilter)


                                }} style={{ fontWeight: 700, color: 'white', padding: '8px 12px' }}><img src={plusCircle} style={{ marginRight: 8 }} />Or Filter</div>


                            </div>}>
                                <div style={{
                                    style: 'cursor-pointer',
                                    width: '174px',
                                    height: '33px',
                                    padding: '8px 16px',
                                    gap: '10px',
                                    borderRadius: '2px 0 0 0',
                                    borderTop: '1px solid transparent',
                                    background: "#26282A",
                                    border: "1px solid #353738",
                                    color: 'white',
                                    fontFamily: 'Montserrat',
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    lineHeight: '14.63px',
                                    textAlign: 'center',
                                    textUnderlinePosition: 'from-font',
                                    textDecorationSkipInk: 'none'
                                }}>
                                    + Add Filter Condition
                                </div>
                            </Dropdown>
                        </div> : <></>
                ))}
            </div>

            <div style={{
                height: 53, paddingTop: 20, borderTop: '1px solid #26282A', marginTop: 20,
            }}>
                <Dropdown
                    trigger={["click"]}
                    open={isDropdownOpen}
                    onOpenChange={(visible) => setIsDropdownOpen(visible)}
                    overlay={
                        <div style={{ background: 'black', padding: 8, width: 174, borderRadius: 16, top: 20 }}>
                            <div style={{ fontWeight: 700, color: 'white', padding: '8px 12px' }}
                                onClick={() => {
                                    updateSelectedCohortFilters({
                                        ...filter,
                                        appliedFilters: [...filter?.appliedFilters, {
                                            type: 'filter',
                                            config: {
                                                condition: 'AND',
                                                operand: '',
                                                operator: '',
                                                type: '',
                                                value: '',
                                            }
                                        }]
                                    });
                                    setIsDropdownOpen(false);
                                }}>
                                <img src={plusCircle} style={{ marginRight: 8 }} /> And Filter
                            </div>
                            <div onClick={() => {
                                updateSelectedCohortFilters({
                                    ...filter,
                                    appliedFilters: [...filter?.appliedFilters, {
                                        type: 'filter',
                                        config: {
                                            condition: 'OR',
                                            operand: '',
                                            operator: '',
                                            type: '',
                                            value: '',
                                        }
                                    }]
                                });
                                setIsDropdownOpen(false);
                            }} style={{ fontWeight: 700, color: 'white', padding: '8px 12px' }}>
                                <img src={plusCircle} style={{ marginRight: 8 }} /> Or Filter
                            </div>
                            <div onClick={() => {
                                updateSelectedCohortFilters({
                                    ...filter,
                                    appliedFilters: [...filter?.appliedFilters, {
                                        type: 'filterGroup',
                                        config: [{
                                            condition: 'AND',
                                            operand: '',
                                            operator: '',
                                            type: '',
                                            value: '',
                                        }]
                                    }]
                                });
                                setIsDropdownOpen(false);
                            }} style={{ fontWeight: 700, color: 'white', padding: '8px 12px' }}>
                                <img src={filterGroup} style={{ marginRight: 8 }} />Filter Group
                            </div>
                        </div>
                    }>
                    <div style={{
                        style: 'cursor-pointer',
                        width: '174px',
                        height: '33px',
                        padding: '8px 16px',
                        gap: '10px',
                        borderRadius: '2px 0 0 0',
                        borderTop: '1px solid transparent',
                        background: "#26282A",
                        border: "1px solid #353738",
                        color: 'white',
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        fontWeight: '700',
                        lineHeight: '14.63px',
                        textAlign: 'center',
                        textUnderlinePosition: 'from-font',
                        textDecorationSkipInk: 'none'
                    }}>
                        + Add Filter Condition
                    </div>
                </Dropdown>
            </div>
        </div >
    </>
}
