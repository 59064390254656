import React, {useEffect, useMemo, useState} from 'react';
import SideDrawer from "../../Components/SideDrawer";
import {Button, Checkbox, Col, Modal, Row, Switch, Tooltip as ToolTip, Typography} from "antd";
import moment from "moment/moment";
import {
    checkPanoramaIDEnabledForPublisher, getDeletedUIDCredentialsAPI,
    getUIDCredentialsAPI, getUIDUserGraphData,
    getUserAuthenticationDashboardData, removeUIDIntegration,
    updatePanoramaIDForPublisher, upsertUIDCredentialsAPI
} from "../../Utils/ApiActions";
import {identitProvidersType} from "../../Utils/Helper";
import {IdentityProviderStatsAuthStrip} from "./components/IdentityProviderStatsAuthStrip";
import {IdentityProviderGraphStrip} from "./components/IdentityProviderlockrGraphStrip";
import {UIDNoCredentialModal} from "../../Components/UIDNoCredentialModal";
import {TitleTextPassword} from "../../Components/TitleTextPassword";
import {LockrInfoIcon, UIDCredentialsModal} from "../../Components/UIDCredentialsModal";

function PanoramaIDCredentialsModal({
                                        display, onOk, onCancel, error, credentials, onHideError = () => {
    },
                                    }) {

    const [modalCredentials, setModalCredentials] = useState(credentials ?? {
        accessKey: "", secretKey: "", useLockrKeys: false,
    });

    useEffect(() => {
        onHideError()
    }, [modalCredentials]);

    function renderTNCLine() {
        return (<div
            style={{
                marginTop: 24, display: "flex", flexDirection: "row", alignItems: "center",
            }}
        >
        <span
            style={{
                marginLeft: 8, color: "#EDEDED", fontFamily: "Montserrat", fontSize: 14, fontWeight: "400",
            }}
        >
          Don't have a Lotame Panorama ID account?
        </span>
            <span
                style={{
                    marginLeft: 8,
                    color: "white",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "600",
                    fontSize: 14,
                }}
                onClick={() => {
                    window.open("https://id5.io/universal-id/lockr/", "_blank");
                }}
            >
                    Create one here
                </span>
        </div>);
    }

    return (
        <Modal
            centered
            width={750}
            footer={null}
            closable={false}
            bodyStyle={{
                minHeight: 400,
                width: 750,
                backgroundColor: "#353738",
                paddingRight: 60,
                paddingLeft: 60,
                paddingTop: 60,
                overflowX: "hidden",
            }}
            destroyOnClose
            style={{
                top: 20,
                paddingBottom: 20,
            }}
            visible={display}
        >
            <img
                style={{right: 60}}
                alt="close"
                onClick={onCancel}
                src={require("../../assets/modal-close.png")}
                className="delete-x-button-connection"
            />
            <div>
                <Typography.Title
                    style={{
                        display: "inline-block",
                        fontFamily: "Montserrat",
                        color: "#fff",
                        fontWeight: 700,
                        fontSize: 20,
                        paddingBottom: 40,
                        paddingTop: 8,
                    }}
                >
                    {"Credentials"}
                </Typography.Title>
            </div>
            <div style={{marginTop: 16}}/>
            <TitleTextPassword
                placeholder={" "}
                label={"Lotame Client ID"}
                marginLeft={98}
                eyeRight={80}
                disabled={modalCredentials.useLockrKeys}
                supportCopy
                supportDisabledColor
                value={
                    modalCredentials?.useLockrKeys
                        ? "***********"
                        : modalCredentials?.accessKey
                }
                hideEye={modalCredentials.useLockrKeys}
                onChange={(event) =>
                    setModalCredentials({
                        ...modalCredentials,
                        accessKey: event.target.value,
                    })
                }
            />
            {/* <div style={{ marginTop: 16 }} /> */}
            {/* <TitleTextPassword
        placeholder={" "}
        label={"ID5 Token"}
        marginLeft={162}
        supportCopy
        eyeRight={65}
        hideEye = {modalCredentials.useLockrKeys}
        disabled={modalCredentials.useLockrKeys}
        supportDisabledColor
        value={
          modalCredentials?.useLockrKeys
            ? "***********"
            : modalCredentials?.secretKey
        }
        onChange={(event) =>
          setModalCredentials({
            ...modalCredentials,
            secretKey: event.target.value,
          })
        }
      /> */}

            <div style={{marginTop: 24}}/>
            <Button
                type="primary"
                className="sign-in"
                onClick={() => {
                    onOk(modalCredentials);
                }}
                style={{
                    width: 217,
                    marginTop: 24,
                    cursor: "pointer",
                }}
            >
                Confirm
            </Button>
            <div>
                <span className="errorText">{error}</span>
            </div>
            {renderTNCLine()}
        </Modal>
    );

}

export function PanoramaIDToken() {

    const [displayLoading, setDisplayLoading] = useState(false);
    const [displayCredentialsModal, setDisplayCredentialsModal] = useState(false);
    const [graphFilterEndDate, setGraphFilterEndDate] = useState(moment());
    const [loginTrendsAppIDs, setLoginTrendsAppIDs] = useState([]);
    const [authDataAppIDs, setAuthDataAppIDs] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [userAuthDataEndDate, setUserAuthDataEndDate] = useState(moment());
    const [userAuthenticationData, setUserAuthenticationData] = useState(undefined);
    const [graphFilterStartDate, setGraphFilterStartDate] = useState(moment().subtract(7, "days"));
    const [panoramaIDCredentials, setPanoramaIDCredentials] = useState(undefined);
    const [deletedCredentials, setDeletedCredentials] = useState(undefined);
    const [endDate, setEndDate] = useState(
        moment().add(1, "day").format("yyyy-MM-DD")
    );
    const [startDate, setStartDate] = useState(
        moment().subtract(1, "month").format("yyyy-MM-DD")
    );

    const [userAuthDataStartDate, setUserAuthDataStartDate] = useState(moment().subtract(7, "days"));

    useEffect(() => {
        window.scrollTo({
            top: 0, left: 0
        });
        getPanoramaIDCredentials();
    }, []);

    useEffect(() => {
        panoramaIDCredentials?.accessKey && getGraphData();
    }, [graphFilterStartDate, graphFilterEndDate, panoramaIDCredentials, loginTrendsAppIDs]);

    useEffect(() => {
        getUserAuthenticationData();
    }, [userAuthDataEndDate, userAuthDataStartDate, authDataAppIDs]);

    const utmUserCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType === "UTM") {
                    result = parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);

    const emailCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType !== "UTM" && item.eventType !== "lockrMail") {
                    result += parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);


    const ssoUserCount = useMemo(() => {
        if (userAuthenticationData?.length) {
            let result = 0;
            userAuthenticationData.forEach((item) => {
                if (item.eventType === "lockrMail") {
                    result = parseInt(item.count);
                }
            });
            return result;
        }
        return 0;
    }, [userAuthenticationData]);


    async function getUserAuthenticationData() {
        try {
            const response = await getUserAuthenticationDashboardData(userAuthDataStartDate, userAuthDataEndDate, identitProvidersType.PANORAMA_ID, authDataAppIDs,);
            if (response.success) {
                setUserAuthenticationData(response.data);
                return;
            }
            setUserAuthenticationData([]);
        } catch (error) {
            console.log("Unable to get the data for the user authentication data");
            console.log(error);
        }
    }

    async function getGraphData() {
        try {
            const response = await getUIDUserGraphData(
                graphFilterStartDate,
                graphFilterEndDate,
                identitProvidersType.PANORAMA_ID,
                loginTrendsAppIDs
            );
            if (response.success) {
                setGraphData(response.data.data);
            }
        } catch (error) {
            console.log("Unable to get the graph data at the moment");
            console.log(error);
        }
    }

    async function getDeletedCredentials() {
        try {
            const {data, success} = await getDeletedUIDCredentialsAPI(identitProvidersType.PANORAMA_ID);
            if (success && data?.accessKey) {
                setDeletedCredentials({...data, isDeleted: false});
            }
        } catch (error) {
            console.log("Unable to get the deleted credentials at the moment");
            console.log(error);
        }
        setDisplayLoading(false);
    }

    async function getPanoramaIDCredentials() {
        try {
            setDisplayLoading(true);
            const {data, success} = await getUIDCredentialsAPI(identitProvidersType.PANORAMA_ID);
            if (success && data?.accessKey) {
                setPanoramaIDCredentials(data);
            } else {
                getDeletedCredentials();
            }
        } catch (error) {
            console.log("Unable to get the panorama credentials at the moment");
            console.log(error);
        }
    }

    async function savePanoramaIDCredentials(credentials) {
        setDisplayLoading(true);
        try {
            await upsertUIDCredentialsAPI(credentials.accessKey, identitProvidersType.PANORAMA_ID, identitProvidersType.PANORAMA_ID, false);
            getPanoramaIDCredentials();
            setDisplayCredentialsModal(false);
        } catch (error) {
            console.log("Unable to save the panorama credentials at the moment");
            console.log(error);
        }
    }


    function dispalyConnectionModal() {
        if (!panoramaIDCredentials && !displayCredentialsModal && !displayLoading) {
            return (<UIDNoCredentialModal
                integrationType={identitProvidersType.PANORAMA_ID}
                onOk={() => setDisplayCredentialsModal(true)}
                display={true}
            />)
        }
    }

    async function removePanoramaIDIntegrationCall() {
        try {
            const response = await removeUIDIntegration(identitProvidersType.PANORAMA_ID);
            if (response.success && response.data) {
                setDeletedCredentials({
                    accessKey: panoramaIDCredentials.accessKey,
                    secretKey: "",
                })
                setPanoramaIDCredentials(undefined);
            }
        } catch (error) {
            console.log("Unable to remove the panorama credentials at the moment");
            console.log(error);
        }
    }

    function displaySwitch() {
        return (<div>
            <Switch
                checked={panoramaIDCredentials !== undefined}
                onChange={(checked) => {
                    !checked && removePanoramaIDIntegrationCall();
                }}
            />
            <Typography.Text
                onClick={() => {
                    setDisplayCredentialsModal(true);
                }}
                className="infosum-view-credentials-text"
            >
                View Credentials
            </Typography.Text>
        </div>)
    }

    function renderStats() {
        return (<div
            style={{
                marginTop: 72, marginLeft: 90, marginRight: 90,
            }}
        >
            <IdentityProviderStatsAuthStrip
                utmCount={utmUserCount}
                ssoCount={ssoUserCount}
                emailCount={emailCount}
                allData={userAuthenticationData}
                startDate={userAuthDataStartDate}
                endDate={userAuthDataEndDate}
                onChangeEndDate={setUserAuthDataEndDate}
                onChangeStartDate={setUserAuthDataStartDate}
                setAuthDataAppIDs={setAuthDataAppIDs}
                authDataAppIDs={authDataAppIDs}
            />
            {/* <div style={{ marginTop: 60 }} />
            <IdentityProviderlockrNMailStrip
              startDate={startDate}
              endDate={endDate}
              onChangeEndDate={setEndDate}
              onChangeStartDate={setStartDate}
              consentedPercentage={getConsentedUserPercent()}
              totalUsers={userCount}
              avgEmails={getAvgConnectedEmails()}
              lockrMailAppIDs={lockrMailAppIDs}
              setLockrMailAppIDs={setLockrMailAppIDs}
            /> */}
            <div style={{marginTop: 60}}/>
            <IdentityProviderGraphStrip
                endDate={graphFilterEndDate}
                startDate={graphFilterStartDate}
                graphData={graphData}
                onChangeEndDate={setGraphFilterEndDate}
                onChangeStartDate={setGraphFilterStartDate}
                setLoginTrendsAppIDs={setLoginTrendsAppIDs}
                loginTrendsAppIDs={loginTrendsAppIDs}
            />
        </div>);
    }

    return (<SideDrawer>
        <Row justify={"space-between"} align={"middle"} className={"apiandservices-newapp-btn"}>
            <Col sm={12} xs={24}>
                <Typography.Title className={"apiandservices-heading"}>
                    Lotame Panorama ID
                </Typography.Title>
                <div style={{marginTop: 8}}/>
                <div
                    style={{
                        display: "flex", flexDirection: "row", width: "100%",
                    }}
                >
                    <label
                        style={{
                            fontSize: 14, fontFamily: "Montserrat", color: "#8B8B8B",
                        }}
                        className="dashboard-as-of-today"
                    >
                        Enable to start a connection
                    </label>
                    <div
                        style={{
                            flex: 1, marginLeft: 20, display: "flex", flexDirection: "row", width: "100%",
                        }}
                    >
                        <div style={{flex: 1}}>{displaySwitch()}</div>
                    </div>
                </div>
            </Col>
        </Row>
        {renderStats()}
        {dispalyConnectionModal()}
        {displayCredentialsModal && <PanoramaIDCredentialsModal
            display={displayCredentialsModal}
            onOk={savePanoramaIDCredentials}
            onCancel={()=>{
                setDisplayCredentialsModal(false);
            }}
            credentials={{
                accessKey: panoramaIDCredentials?.accessKey ?? deletedCredentials?.accessKey,
                secretKey: "",
                useLockrKeys: false,
            }}
        />}
    </SideDrawer>)
}
