import { Button, Space, Table, Typography, message, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import InitiateConnectionModal from "../../../Components/InitiateConnectionModal";
import { identitProvidersType } from "../../../Utils/Helper";
import {
  deleteBatchProcess,
  fetchBatchData,
  startProcess,
} from "../../../Utils/ApiActions";
import { ShowDeleteConfirm } from "../../../App";

// Function to get the identity provider name based on type
function getIdentityProviderName(type) {
  const providerNames = {
    [identitProvidersType.UID]: "UID2",
    [identitProvidersType.RAMPID]: "LiveRamp",
    [identitProvidersType.ID5]: "ID5",
    [identitProvidersType.EUID]: "EUID",
  };

  return providerNames[type] || "Unknown Provider";
}

export function BatchUpload({ idType = identitProvidersType.RAMPID }) {
  // Component to display status dots
  const StatusDot = ({ color }) => (
    <span
      style={{
        height: 12,
        width: 12,
        borderWidth: 1,
        borderRadius: "50%",
        background: color,
        marginRight: "8px",
      }}
    />
  );

  // State management
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  // Track which row is loading (entire row spinner).
  const [rowLoadingId, setRowLoadingId] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedBatchId, setSelectedBatchId] = useState(null);

  // Function to fetch batch details from the API
  const fetchBatchDetails = async (callLoading = true) => {
    if(callLoading) {
      setLoading(true);
    }
    try {
      const response = await fetchBatchData(idType);
      setDataSource(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error("Error fetching batch details:", error);
      // message.error("Failed to fetch batch details.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchBatchDetails();
  }, []);

  useEffect(() => {
    console.log("Modal state changed:", isModalOpen);
  }, [isModalOpen]);

  // Handle modal open with type
  const OpenConnectionModal = (type = "connection", batchProcessID) => {
    setModalType(type);
    setIsModalOpen(true);
    if (batchProcessID) {
      setSelectedBatchId(batchProcessID);
    }
  };

  // Handle modal close and refresh data
  const closConnectioneModal = () => {
    setIsModalOpen(false);
    setModalType(null); // Reset modal type
    fetchBatchDetails();
  };

  // Helper to mark a row as Failed with error message
  // const markRowFailed = (recordId, errorMsg) => {
  //   setDataSource((prevData) =>
  //     prevData.map((row) =>
  //       row.id === recordId
  //         ? { ...row, status: "Failed", errorMessage: String(errorMsg) }
  //         : row
  //     )
  //   );
  // };

  // Action button handlers
  const handleRunAnalysis = async (record) => {
    try {
      // Show entire row as loading
      setRowLoadingId(record.id);
      await startProcess(record.id);
      // If successful, fetch the updated data
      fetchBatchDetails(false);
    } catch (error) {
      console.error("Error running analysis:", error);
      fetchBatchDetails(false);
      // Mark the row as Failed, storing the error message for tooltip
      // markRowFailed(record.id, error.message || "Failed to start analysis.");
      // markRowFailed(record.id, "Failed to start analysis.");

    } finally {
      // Reset the loading state after operation
      setRowLoadingId(null);
    }
  };

  const handleRemoveRecord = async (record) => {
    const recordId = record.id ? record.id : record;
    try {
      // Show entire row as loading
      setRowLoadingId(recordId);
      await deleteBatchProcess(recordId);
      // If successful, refresh data
      fetchBatchDetails(false);
    } catch (error) {
      console.error("Error removing record:", error);
      fetchBatchDetails(false);
      // markRowFailed(recordId, error.message || "Failed to remove the record.");
      // markRowFailed(recordId, "Failed to remove the record.");

    } finally {
      setRowLoadingId(null);
    }
  };

  // Table columns
  const logsColumns = [
    {
      title: "File Name",
      key: "fileName",
      dataIndex: "fileName",
      width: "20%",
      align: "left",
    },
    {
      title: "Upload Date/Time",
      key: "date_time",
      dataIndex: "createdAt",
      width: "20%",
      align: "right",
      render: (text) => new Date(text).toLocaleString(), // Format date
    },
    {
      title: `${getIdentityProviderName(idType)} Token Count`,
      key: "token_count",
      dataIndex: "totalRecords",
      width: "20%",
      align: "right",
      render: (count) => parseInt(count, 10).toLocaleString(), // Format number
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      align: "right",
      width: "20%",
      render: (status, record) => {
        let color = "";
        if (status === "Loaded") color = "#8B8B8B";
        if (status === "Processing") color = "#FAC748";
        if (status === "Completed") color = "#50E8B1";
        if (status === "Failed") color = "#f5222d";

        const statusElement = (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <StatusDot color={color} />
            <span
              style={{
                color: "white",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              {status}
            </span>
          </div>
        );

        // If row is Failed and has an error message, show tooltip
        if (status === "Failed" && record.errorMessage) {
          return (
            <Tooltip title={record.errorMessage?.split("-")[0]}>
              {statusElement}
            </Tooltip>
          );
        }
        return statusElement;
      },
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      align: "right",
      width: "20%",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Space>
              {(record.status === "Loaded" || record.status === "Failed") && (
                <Button
                  className="batch-button"
                  type="primary"
                  onClick={() => handleRunAnalysis(record)}
                >
                  Run Analysis
                </Button>
              )}
              {record.status === "Processing" && (
                <Button
                  type="primary"
                  className="batch-button batch-button-disabled"
                  disabled
                >
                  Run Analysis
                </Button>
              )}
              {record.status === "Completed" && (
                <Button
                  type="primary"
                  className="batch-button"
                  onClick={() => OpenConnectionModal("endpoint", record.id)}
                >
                  Select Data Endpoint
                </Button>
              )}
              <ShowDeleteConfirm
                displayLoading={loading}
                record={record}
                title={"Delete Batch"}
                confirmDelete={handleRemoveRecord}
              />
            </Space>
          </div>
        );
      },
    },
  ];

  // Custom Row Component to overlay spinner
  const CustomRow = (props) => {
    const { children, ...restProps } = props;
    const dataRowKey = props["data-row-key"];
    // console.log(dataRowKey);
    const isLoading = rowLoadingId === dataRowKey;

    return (
      <tr {...restProps} style={{ position: "relative" }}>
        {children}
        {isLoading && (
          <td
            colSpan={logsColumns.length}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 1)",
              borderBottom: "1px solid #26282a",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <Spin />
          </td>
        )}
      </tr>
    );
  };

  // Custom components for the Table
  const tableComponents = {
    body: {
      row: CustomRow,
    },
  };

  return (
    <>
      <div style={{ marginTop: 50 }} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "24px",
          color: "white",
          fontFamily: "Montserrat",
          marginBottom: 20,
        }}
      >
        {/* Status Indicators */}
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <StatusDot color="#8B8B8B" />
          <div style={{ fontSize: "14px", lineHeight: "18px" }}>Loaded</div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <StatusDot color="#FAC748" />
          <div style={{ fontSize: "14px", lineHeight: "18px" }}>
            Processing
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <StatusDot color="#50E8B1" />
          <div style={{ fontSize: "14px", lineHeight: "18px" }}>
            Completed
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <StatusDot color="#f5222d" />
          <div style={{ fontSize: "14px", lineHeight: "18px" }}>Failed</div>
        </div>
      </div>
      <div
        style={{ background: "black", padding: "20px", borderRadius: "8px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title
            style={{
              fontSize: "20px",
              fontWeight: "700",
              fontFamily: "Montserrat",
              marginBottom: "0",
            }}
          >
            Batch upload {getIdentityProviderName(idType)}
          </Typography.Title>
          <Button
            className="dashboard-download-btn"
            style={{ marginTop: "0px" }}
            type="primary"
            shape="default"
            onClick={() => OpenConnectionModal()}
          >
            Initiate Connection
          </Button>
        </div>
        {loading ? (
          <div style={{ textAlign: "center", padding: "50px 0" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Table
            components={tableComponents}
            scroll={{ x: "max-content" }}
            style={{ overflowWrap: "anywhere", marginTop: "24px" }}
            className="dashboard-tables no-border-table"
            rowKey="id"
            columns={logsColumns}
            dataSource={dataSource}
            pagination={{ pageSize: 10 }}
            locale={{
              emptyText: (
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px 0",
                    color: "#8B8B8B",
                    fontSize: "16px",
                  }}
                >
                  No batch data found.
                </div>
              ),
            }}
          />
        )}
      </div>
      <InitiateConnectionModal
        visible={isModalOpen}
        onClose={closConnectioneModal}
        idType={idType}
        type={modalType}
        batchProcessID={selectedBatchId}
        batchData={dataSource.find((data) => data.id === selectedBatchId)}
      />
    </>
  );
}
