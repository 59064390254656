import React, { useState } from "react";
import {
  Layout,
  Menu,
  Row,
  Col,
  PageHeader,
  Avatar,
  Dropdown,
  Button,
  Typography,
} from "antd";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { Footer } from "antd/lib/layout/layout";
import moment from 'moment';
import { userStore } from "../Store/UserStore";
import { FEATURES, getAllowedRoleFeatures } from "../Utils/Helper";
import { DOCS_URL } from "../Utils/ApiActions";

const { Content, Sider } = Layout;
function SideDrawer(props) {
  let location = useLocation();

  const logout = () => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("authToken");
    localStorage.removeItem("publisherName");
    props.history.push("/console/");
  };

  const userMenu = (
    <userStore.Consumer>
      {({ state }) => {
        const role = state.userRole;
        const hasUserAccess = getAllowedRoleFeatures(role).includes(FEATURES.USERS_READ);
        return (
          <Menu style={{ height: hasUserAccess ? '170px' : '130px' }} className="profile-dropdown-menu">
            <Menu.Item
              key="1"
              className="profile-dropdown-text"
              style={{ background: "black", color: "#8B8B8B", marginTop: 12 }}
            >
              <Link to={"/console/profile"}>
                <Typography.Text className="account-menu-typography">Account</Typography.Text>
              </Link>
            </Menu.Item>
            {hasUserAccess && <Menu.Item key={"3"} className={"profile-dropdown-text"}
              style={{ background: 'black', color: '#8B8B8B', marginTop: 4 }}
            >
              <Link to={'/console/users'}>
                <Typography.Text className="account-menu-typography">Users</Typography.Text>
              </Link>
            </Menu.Item>}
            <Menu.Item onClick={() => {
              if (location.pathname === '/console/contact') {
                props.history.go(0);
                return;
              }
              props.history.push('/console/contact')
            }} className="profile-dropdown-text">
              <Typography.Text className="account-menu-typography">Support</Typography.Text>
            </Menu.Item>
            <Menu.Item
              key="2"
              onClick={logout}
              className="profile-dropdown-text"
              style={{ background: "black", color: "#8B8B8B", marginTop: 4 }}
            >
              <label>Log out</label>
            </Menu.Item>
          </Menu>)
      }}
    </userStore.Consumer>
  );

  return (
    <Layout style={{ minHeight: "100vh", background: props.background ?? "#171717" }}>
      <userStore.Consumer>
        {({ state }) => {
          const role = state.userRole;
          const allowedFeatures = getAllowedRoleFeatures(role);
          return (
            <PageHeader
              className="site-page-header"
              title={
                <div style={{ flexDirection: "row" }}>
                  <img
                    className="lockr-logo-pagehead"
                    src={require("../assets/lockr-logo.png")}
                  ></img>
                  {/* <label style = {{color: "white", marginLeft: 16}}>{"Publisher Platform"}</label> */}
                </div>
              }
              // style={{
              //   backgroundColor: "#171717",
              //   borderBottomWidth: "1px",
              //   paddingLeft: "91px",
              //   paddingRight: "78px",
              // }}
              extra={[
                <div
                  style={{ display: "inline-block" }}
                  className="page-header-options"
                >
                  {allowedFeatures.includes(FEATURES.DASHBOARD_READ) && <Link
                    style={{
                      color:
                        location.pathname == "/console/dashboard"
                          ? "#61CEF7"
                          : "#fff",
                      paddingRight: 40,
                    }}
                    to={"/console/dashboard"}
                  >
                    Dashboard{" "}
                  </Link>}
                  {allowedFeatures.includes(FEATURES.API_SERVICES_READ) && <Link
                    style={{
                      color:
                        location.pathname == "/console/apiandservices"
                          ? "#61CEF7"
                          : "#fff",
                      paddingRight: 40,
                    }}
                    to={"/console/apiandservices"}
                  >
                    API & Services{" "}
                  </Link>}

                  {allowedFeatures.includes(FEATURES.ANALYTICS_READ) && <Link
                    style={{
                      color:
                        location.pathname == "/console/analytics"
                          ? "#61CEF7"
                          : "#fff",
                      paddingRight: 40,
                    }}
                    to={"/console/analytics"}
                  >
                    Analytics{" "}
                  </Link>}
                  {allowedFeatures.includes(FEATURES.API_SERVICES_READ) && <Link
                    style={{
                      color:
                        location.pathname == "/console/csv-data-endpoint"
                          ? "#61CEF7"
                          : "#fff",
                      paddingRight: 40,
                    }}
                    to={"/console/csv-data-endpoint"}
                  >
                    Segments{" "}
                  </Link>}
                  {allowedFeatures.includes(FEATURES.CONNECTIONS_READ) && <Link
                    to={'/console/connections'}
                    style={{
                      color: location.pathname === '/console/connectionList' || location.pathname === '/console/connections' ? '#61CEF7' : '#fff',
                      paddingRight: 40
                    }}
                  >Connections</Link>}
                  <Button
                    style={{
                      background: "transparent",
                      color: "#fff",
                      marginRight: 40,
                      border: "1px solid #353738",
                    }}
                    onClick={() => {
                      window.open(DOCS_URL);
                    }}
                  >
                    <BsBoxArrowUpRight style={{ marginRight: "10px" }} size={12} />{" "}
                    API docs
                  </Button>
                </div>,
                <Dropdown
                  placement="bottomCenter"
                  overlay={userMenu}
                  trigger={["click"]}
                >
                  <img
                    className="avatar-icon"
                    src={require("../assets/avatar_profile.png")}
                  ></img>
                </Dropdown>,
              ]}
            />
          )
        }}
      </userStore.Consumer>
      <Layout style={{ backgroundColor: "#1d1d1d", minHeight: "100%" }}>
        {/* <Sider
          className='menuSider'
          breakpoint="lg"
          collapsedWidth="0"
          style={{
          minHeight: "100vh"
        }}>
          <Menu mode='vertical' selectable={true} style={{
            background: "#171717", paddingTop: 25, minHeight: '100%'
          }}>
            <Menu.Item style={{ color: '#fff' }}>
              <Link style={{ color: '#fff' }} to={"/console/dashboard"}>
                Dashboard
              </Link>
            </Menu.Item>
            <Menu.Item style={{ color: '#fff' }}>
              <Link style={{ color: '#fff' }} to={"/console/analytics"}>
                Analytics
              </Link>
            </Menu.Item>
            <Menu.Item style={{ color: '#fff' }}>API Reference</Menu.Item>
          </Menu>
        </Sider> */}
        <Content style={{ backgroundColor: props.background ?? "#171717" }}>
          {props.children}
        </Content>
      </Layout>
      <Footer className="footer-style">
        <Row justify="space-between">
          <Col sm={12}>
            <div>
              <img
                src={require("../assets/footer-logo.png")}
              // className="footer-logo"
              ></img>
              {/* <Typography.Text
                style={{ color: "#8B8B8B", fontSize: 14, marginLeft: 24 }}
              >
                Documentation Powered by{" "}
              </Typography.Text> */}
              {/* <Typography.Text style={{ color: "#61CEF7", fontSize: 14 }}>
                ReDoc
              </Typography.Text> */}
            </div>
          </Col>
          <Col sm={12} style={{ textAlign: "end" }}>
            <Typography.Text
              style={{ color: "#8B8B8B", fontSize: 14, marginLeft: 24 }}
            >
              &copy; {moment().format('YYYY')} lockr
            </Typography.Text>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
}

export default withRouter(SideDrawer);
